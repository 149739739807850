import type { ReactNode, Ref } from "preact/compat";
import { useEffect, useRef } from "preact/hooks";
import { mergeRefs } from "react-merge-refs";
import { clsx } from "clsx";
import { compact } from "lodash-es";
import type { ButtonProps } from "~/components/button.tsx";
import Button from "~/components/button.tsx";
import { useTranslator } from "~/i18n/context.tsx";
import CloseIcon from "./icons/cross.tsx";
import classes from "./modal.module.css";

type ActionButtonProps = ButtonProps & {
	readonly key: string;
};

type ModalProps = {
	readonly id: string;
	readonly dialogRef?: Ref<HTMLDialogElement>;
	readonly size: "small" | "medium" | "large" | "full";
	readonly showModalOnMount?: boolean;
	/** @deprecated Needs a rework/check */
	readonly cancelActionButton?: boolean | string;
	/** @deprecated Needs a rework/check */
	readonly actionButtons?: readonly ActionButtonProps[];
	readonly working?: boolean;
	readonly bodyClassName?: string;
	readonly children: ReactNode;
};

function Modal({
	id,
	dialogRef: providedDialogRef,
	showModalOnMount,
	cancelActionButton,
	actionButtons,
	bodyClassName,
	size,
	working,
	children,
}: ModalProps) {
	const dialogRef = useRef<HTMLDialogElement>(null);

	const workingRef = useRef(working);
	workingRef.current = working;

	useEffect(() => {
		const { current: dialog } = dialogRef;
		if (!dialog) {
			return;
		}

		const onCancel = (e: Event) => {
			e.preventDefault();
			dialogRef.current?.close();
		};

		dialog.addEventListener("cancel", onCancel);

		return () => {
			dialog.removeEventListener("cancel", onCancel);
		};
	}, [workingRef]);

	const t = useTranslator();
	const allActions: readonly ActionButtonProps[] = compact([
		cancelActionButton && {
			key: "cancel",
			children:
				typeof cancelActionButton === "string"
					? cancelActionButton
					: t("Cancel"),
			variant: "black-line" as const,
			type: "button" as const,
			commandfor: id,
			command: "close",
			disabled: working,
		},
		...(actionButtons ?? []),
	]);

	// Show on mount
	const showModalOnMountRef = useRef(showModalOnMount);
	useEffect(() => {
		if (!showModalOnMountRef.current) {
			return;
		}

		const { current: currentDialog } = dialogRef;
		if (currentDialog === null) {
			throw new Error("Invalid state - no dialog ref");
		}
		currentDialog.showModal();
	}, []);

	return (
		<dialog
			id={id}
			ref={mergeRefs([dialogRef, providedDialogRef])}
			className={clsx(classes["modal"], classes[`modal-${size}`])}
		>
			<div className={classes["modal-container"]}>
				<button
					className={classes["close-button"]}
					aria-label={t("Close")}
					type="button"
					disabled={working}
					commandfor={id}
					command="close"
				>
					<CloseIcon />
				</button>
				<div className={classes["modal-main"]}>
					<div className={clsx(classes["modal-body"], bodyClassName)}>
						{children}
					</div>
					{allActions.length > 0 && (
						<div className={classes["modal-actions"]}>
							{allActions.map(({ key: actionKey, ...a }) => (
								<Button key={actionKey} {...a} />
							))}
						</div>
					)}
				</div>
			</div>
		</dialog>
	);
}

export type { ModalProps };
export default Modal;
