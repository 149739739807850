function LeftClickMouseIcon() {
	return (
		<svg
			width="66"
			height="90"
			viewBox="0 0 66 90"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M31 17H40.5L42 18L41.5 26.5L39 29.5V37L41.5 40V45.5H20V30L23 22L31 17Z"
				fill="url(#paint0_radial_376_2231)"
			/>
			<path
				d="M48.9551 15.52H35.0432C25.6461 15.52 18 23.1661 18 32.5633V65.2606C18 78.494 28.7658 89.2597 42.0006 89.2597C55.2355 89.2597 66.0012 78.494 66.0012 65.2606V32.5633C66.0012 23.1646 58.3551 15.5186 48.9565 15.5186L48.9551 15.52ZM62.6984 32.5633V45.0684H43.6506V41.6556C45.8456 40.9684 47.4428 38.9155 47.4428 36.4977V31.602C47.4428 29.1827 45.8456 27.1313 43.6506 26.444V18.8214H48.9551C56.5323 18.8214 62.6969 24.986 62.6969 32.5633H62.6984ZM42.0387 29.5007C43.1978 29.5007 44.1415 30.4444 44.1415 31.6035V36.4991C44.1415 37.6582 43.1978 38.6019 42.0387 38.6019C40.8796 38.6019 39.936 37.6582 39.936 36.4991V31.6035C39.936 30.4444 40.8796 29.5007 42.0387 29.5007ZM35.0447 18.8214H40.3492V26.4704C38.1952 27.1811 36.6346 29.212 36.6346 31.602V36.4977C36.6346 38.8876 38.1937 40.9186 40.3492 41.6292V45.0684H21.3014V32.5633C21.3014 24.986 27.466 18.8214 35.0432 18.8214H35.0447ZM42.0006 85.9598C30.5872 85.9598 21.3028 76.674 21.3028 65.2621V48.3712H62.6984V65.2621C62.6984 76.6755 53.4126 85.9598 42.0006 85.9598Z"
				fill="#E5E5E5"
			/>
			<g clip-path="url(#clip0_376_2231)">
				<path
					d="M17.4545 17.4671C18.2335 16.6917 18.2352 15.4306 17.4598 14.6534L10.6737 7.84432C9.89826 7.06538 8.63712 7.06362 7.85995 7.83902C7.08101 8.61443 7.07924 9.87557 7.85465 10.6527L14.6408 17.4618C15.0294 17.8522 15.5398 18.0465 16.0503 18.0465C16.5608 18.0465 17.0659 17.8522 17.4545 17.4654V17.4671Z"
					fill="#F7B907"
				/>
				<path
					d="M22.0328 9.92469C22.1494 10.9403 23.0096 11.6875 24.0075 11.6875C24.0835 11.6875 24.1594 11.6839 24.2371 11.6751C25.3287 11.5497 26.113 10.5641 25.9876 9.47075L25.1044 1.7626C24.979 0.671026 23.9916 -0.113213 22.9001 0.0121947C21.8085 0.137602 21.0242 1.1232 21.1496 2.21654L22.0328 9.92469Z"
					fill="#F7B907"
				/>
				<path
					d="M9.9161 22.0416L2.22385 21.1302C1.13227 21.0012 0.14314 21.7802 0.0141995 22.8717C-0.114741 23.9633 0.6642 24.9524 1.75578 25.0832L9.44803 25.9946C9.52752 26.0034 9.607 26.0087 9.68472 26.0087C10.6791 26.0087 11.5376 25.2651 11.6577 24.253C11.7866 23.1614 11.0077 22.1723 9.9161 22.0416Z"
					fill="#F7B907"
				/>
			</g>
			<defs>
				<radialGradient
					id="paint0_radial_376_2231"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(31 31.25) rotate(90) scale(14.25 11)"
				>
					<stop stop-color="#FFB2BA" />
					<stop offset="1" stop-color="#E82F41" />
				</radialGradient>
				<clipPath id="clip0_376_2231">
					<rect width="26" height="26.0088" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
}

export default LeftClickMouseIcon;
