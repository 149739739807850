import { findOnlyIndexOrThrow } from "@dhau/lang-extras";
import { useCallback, useEffect, useReducer, useState } from "preact/hooks";
import { clsx } from "clsx";
import type { GenericSize, Palette } from "@brickme/project-core/src";
import Button from "~/components/button.tsx";
import { useTranslator } from "~/i18n/context.tsx";
import ArrowLeftIcon from "~/components/icons/arrow-left.tsx";
import ArrowRightIcon from "~/components/icons/arrow-right.tsx";
import SummaryText from "~/components/summary-text.tsx";
import useLocalStorage from "~/hooks/use-local-storage.ts";
import ChevronUpIcon from "~/components/icons/chevron-up-icon.tsx";
import CheckIcon from "./check-icon.tsx";
import GridIcon from "./grid-icon.tsx";
import PictureRender from "./render/picture-render.tsx";
import LayoutMenu from "./layout/layout-menu.tsx";
import AdjustmentsMenu from "./adjustments/adjustments-menu.tsx";
import { EditorProvider, usePicture } from "./context.tsx";
import CompleteKitOnlyMenu from "./complete/complete-kit-only-menu.tsx";
import type { Mode } from "./mode.ts";
import ConfirmRestartModal from "./confirm-restart-modal.tsx";
import type { SourceImage } from "./source-image.ts";
import LevelsIcon from "./levels-icon.tsx";
import ChooseLayoutOverlay from "./layout/choose-layout-overlay.tsx";
import TryItOutCompleteMenu from "./complete/try-it-out-complete-menu.tsx";
import CompleteColoursMenu from "./complete/complete-colours-menu.tsx";
import ColoursCostSummary from "./cost-summary/colours-cost-summary.tsx";
import classes from "./editor.module.css";
import { useShopify } from "~/shopify-api/context.tsx";

const confirmRestartModalId = "confirm-restart-modal";

type EditorProps = {
	readonly onRestart: () => void;
	readonly numberOfKits: number;
	readonly code: string | undefined;
};

function Editor({ numberOfKits, onRestart, code }: EditorProps) {
	const t = useTranslator();

	// Relies on layout being the first/default step
	const [showLayoutOverlay, hideLayoutOverlay] = useReducer(() => false, true);
	useEffect(() => {
		const timeoutId = setTimeout(hideLayoutOverlay, 5_000);
		return () => {
			clearTimeout(timeoutId);
			// Dispatch has no TS variant with no arg :(.
			hideLayoutOverlay(undefined);
		};
	}, []);
	const layoutOverlay = useCallback(
		(size: GenericSize<number>) => <ChooseLayoutOverlay size={size} />,
		[],
	);

	// Mode
	const [mode, setMode] = useState<Mode>("kit-only");
	const { setColoursRequired } = useShopify();
	const onChangeMode = (newMode: Mode) => {
		if (newMode === "colour") {
			setColoursRequired();
		}
		setMode(newMode);
		setActiveStepIndex(
			findOnlyIndexOrThrow(steps, (s) => s.id === "adjustments"),
		);
	};

	// Step details
	const [showStepDetails, setShowStepDetails] = useLocalStorage<boolean>(
		"showStepDetails",
		true,
		JSON.parse,
		JSON.stringify,
	);

	const { resetPicture } = usePicture();

	// Steps
	const steps = [
		{
			id: "layout" as const,
			label: t("Layout"),
			icon: <GridIcon />,
			heading: t("Choose layout"),
			summary: t(
				"Select the layout variation depending on your number of kits owned.",
			),
			menu: <LayoutMenu numberOfKits={numberOfKits} />,
		},
		{
			id: "adjustments" as const,
			label: t("Adjust"),
			icon: <LevelsIcon />,
			heading: t("Adjust image"),
			summary: t(
				"Using controls below, add/reduce detail, brightness, contrast or remove the background completely.",
			),
			menu: <AdjustmentsMenu mode={mode} hasCode={!!code} />,
		},
		(() => {
			if (!code) {
				return {
					id: "complete" as const,
					label: t("Finish"),
					icon: <CheckIcon />,
					heading: t("Buy a kit"),
					summary: t(
						"If you’re happy with your results, purchase a kit today and start building!",
					),
					menu: <TryItOutCompleteMenu onBackToHome={onRestart} />,
				};
			}

			if (mode === "kit-only") {
				return {
					id: "complete" as const,
					label: t("Finish"),
					icon: <CheckIcon />,
					heading: t("Complete"),
					summary: t(
						"All done! Click the button below to get your instructions.",
					),
					menu: (
						<CompleteKitOnlyMenu
							code={code}
							numberOfKits={numberOfKits}
							onChangeMode={onChangeMode}
						/>
					),
				};
			}

			return {
				id: "complete" as const,
				label: t("Finish"),
				icon: <CheckIcon />,
				heading: t("Complete + Colour"),
				summary: t(
					"If you’re happy with your results, you can download instructions below and buy the colour brick packs.",
				),
				menu: (
					<CompleteColoursMenu
						code={code}
						numberOfKits={numberOfKits}
						onChangeMode={onChangeMode}
					/>
				),
			};
		})(),
	];
	const [activeStepIndex, setActiveStepIndex] = useState(0);
	const activeStep = steps[activeStepIndex];

	return (
		<>
			<ConfirmRestartModal
				id={confirmRestartModalId}
				onBackToUpload={onRestart}
				onReset={resetPicture}
			/>
			<div className={classes["editor"]}>
				<header>
					{activeStepIndex === 0 ? (
						<Button
							type="button"
							variant="secondary"
							commandfor={confirmRestartModalId}
							command="show-modal"
						>
							<ArrowLeftIcon size={12} />{" "}
							<div className={classes["nav-button-label"]}>{t("Back")}</div>
						</Button>
					) : (
						<Button
							type="button"
							variant="secondary"
							onClick={() => {
								setActiveStepIndex((p) => Math.max(0, p - 1));
							}}
						>
							<ArrowLeftIcon size={12} />{" "}
							<div className={classes["nav-button-label"]}>{t("Back")}</div>
						</Button>
					)}
					<nav className={classes["steps"]}>
						{steps.map((s, i) => (
							<button
								key={s.label}
								type="button"
								onClick={() => setActiveStepIndex(i)}
								aria-label={s.label}
								className={clsx(
									classes["step"],
									i === activeStepIndex && classes["step-active"],
								)}
							>
								<div className={classes["icon"]}>{s.icon}</div>
								<div className={classes["step-label"]}>{s.label}</div>
							</button>
						))}
					</nav>
					<Button
						type="button"
						variant="secondary"
						onClick={() =>
							setActiveStepIndex((p) => Math.min(steps.length - 1, p + 1))
						}
						className={
							activeStepIndex >= steps.length - 1 &&
							classes["next-button-hidden"]
						}
					>
						<div className={classes["nav-button-label"]}>{t("Next")}</div>{" "}
						<ArrowRightIcon size={12} />
					</Button>
				</header>
				<main className={classes["main"]}>
					{!!code && mode === "colour" && <ColoursCostSummary />}
					<section
						className={clsx(
							classes["step-details"],
							showStepDetails && classes["step-details-show"],
						)}
					>
						<div className={classes["current-step-indicator"]}>
							{t("STEP")} {activeStepIndex + 1}
						</div>
						<div className={classes["current-step-content"]}>
							<h1 className="heading-1">{activeStep.heading}</h1>
							<SummaryText className={classes["step-summary"]}>
								{activeStep.summary}
							</SummaryText>
						</div>
						<button
							type="button"
							className={classes["step-details-toggle"]}
							onClick={() => {
								setShowStepDetails(!showStepDetails);
							}}
						>
							<ChevronUpIcon />
						</button>
					</section>
					<div className={classes["picture-render-container"]}>
						<PictureRender
							className={classes["picture-render"]}
							overlay={showLayoutOverlay ? layoutOverlay : undefined}
						/>
					</div>
				</main>
				{activeStep.menu}
			</div>
		</>
	);
}

type EditorContainerProps = EditorProps & {
	readonly sourceImage: SourceImage;
	readonly systemPalette: Palette;
};

function EditorContainer({
	sourceImage,
	numberOfKits,
	systemPalette,
	...rest
}: EditorContainerProps) {
	return (
		<EditorProvider
			sourceImage={sourceImage}
			systemPalette={systemPalette}
			numberOfKits={numberOfKits}
		>
			<Editor numberOfKits={numberOfKits} {...rest} />
		</EditorProvider>
	);
}

export default EditorContainer;
