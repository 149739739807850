import { useState, useEffect } from "preact/hooks";
import {
	bitmapToImageData,
	resizeBitmap,
	rotateBitmapAtRightAngles,
} from "@brickme/project-core/src/bitmap/index.ts";
import { runGeneratorSync } from "@brickme/project-core/src/utils/run-generator.ts";
import SimplePage from "~/components/simple-page.tsx";
import type { SourceImage } from "~/features/editor/source-image.ts";
import { useTranslator } from "~/i18n/context.tsx";
import SummaryText from "~/components/summary-text.tsx";
import SingleButtonBottomSheet from "~/components/sheet/single-button-bottom-sheet.tsx";
import Button from "~/components/button.tsx";
import type { SourceRotation } from "../config.ts";
import { sourceRotations } from "../config.ts";
import UploadIcon from "./upload-icon.tsx";
import RotateIcon from "./rotate-icon.tsx";
import classes from "./image-confirm.module.css";

type ImageConfirmProps = {
	readonly sourceImage: SourceImage;
	readonly onComplete: (rotation: SourceRotation) => void;
	readonly onCancel: () => void;
};

function ImageConfirm({
	sourceImage: { bitmap: sourceBitmap },
	onComplete,
	onCancel,
}: ImageConfirmProps) {
	const t = useTranslator();

	const onNextClick = () => {
		onComplete(rotation);
	};

	// Rotation
	const [rotation, setRotation] = useState<SourceRotation>(0);
	const onRotate = () => {
		const currentIndex = sourceRotations.indexOf(rotation);
		if (currentIndex === -1) {
			throw new Error(`Couldn't find rotation ${rotation}`);
		}
		const newIndex = (currentIndex + 1) % sourceRotations.length;
		setRotation(sourceRotations[newIndex]);
	};

	// Render
	const [canvasContainerRef, setCanvasContainerRef] =
		useState<HTMLElement | null>(null);
	const [canvasRef, setCanvasRef] = useState<HTMLCanvasElement | null>(null);
	useEffect(() => {
		function renderCanvas() {
			if (canvasRef === null || canvasContainerRef === null) {
				return;
			}

			const proposedSize = canvasContainerRef.offsetWidth;
			const widthSize = proposedSize <= 700 ? proposedSize : proposedSize * 0.5;
			// 400 approximation of chrome;
			const heightSize = window.innerHeight - 500;
			const size = Math.min(widthSize, heightSize);
			canvasRef.width = size;
			canvasRef.height = size;

			// Rotate
			const rotatedBitmap = rotateBitmapAtRightAngles(sourceBitmap, rotation);

			// Resize
			const coverWidthScale = size / rotatedBitmap.width;
			const coverHeightScale = size / rotatedBitmap.height;
			const finalScale = Math.max(coverWidthScale, coverHeightScale);
			const resizedBitmap = runGeneratorSync(
				resizeBitmap(
					rotatedBitmap,
					Math.round(rotatedBitmap.width * finalScale),
					Math.round(rotatedBitmap.height * finalScale),
				),
			);

			// Draw
			const imageData = bitmapToImageData(resizedBitmap);
			const ctx = canvasRef.getContext("2d");
			if (ctx === null) {
				throw new Error("Couldn't get 2d context");
			}
			ctx.putImageData(
				imageData,
				(size - resizedBitmap.width) / 2,
				(size - resizedBitmap.height) / 2,
			);
		}

		renderCanvas();

		window.addEventListener("resize", renderCanvas);
		return () => {
			window.removeEventListener("resize", renderCanvas);
		};
	}, [canvasRef, sourceBitmap, canvasContainerRef, rotation]);

	return (
		<SimplePage
			onBackClick={onCancel}
			bottomSheet={
				<SingleButtonBottomSheet>
					<Button type="button" variant="blue" onClick={onNextClick}>
						{t("Next")}
					</Button>
				</SingleButtonBottomSheet>
			}
		>
			<div className={classes["page"]}>
				<div className={classes["main-text"]}>
					<h1 className="heading-1">{t("Your selected image")}</h1>
					<SummaryText>
						{t("You can zoom in / crop the image in the next stage.")}
					</SummaryText>
					<SummaryText>
						{t(
							"Select the area of the image by zooming in (pinch) or by dragging the image to the correct spot (one finger).",
						)}
					</SummaryText>
				</div>
				<div className={classes["image-and-controls"]}>
					<div
						ref={setCanvasContainerRef}
						className={classes["canvas-container"]}
					>
						<canvas ref={setCanvasRef} className={classes["source-image"]} />
					</div>
					<div className={classes["controls"]}>
						<button
							type="button"
							onClick={onCancel}
							className={classes["control-button"]}
						>
							<UploadIcon />
							<div>{t("Re-upload")}</div>
						</button>
						<div className={classes["control-divider"]} />
						<button
							type="button"
							onClick={onRotate}
							className={classes["control-button"]}
						>
							<RotateIcon />
							<div>{t("Rotate")}</div>
						</button>
					</div>
				</div>
			</div>
		</SimplePage>
	);
}

export default ImageConfirm;
