import Button from "~/components/button.tsx";
import type { ModalProps } from "~/components/modal.tsx";
import Modal from "~/components/modal.tsx";
import { useTranslator } from "~/i18n/context.tsx";
import classes from "./remove-colour-modal.module.css";

type RemoveColourModalProps = Pick<ModalProps, "id"> & {
	readonly onConfirm: () => void;
};

function RemoveColourModal({ id, onConfirm }: RemoveColourModalProps) {
	const t = useTranslator();
	return (
		<Modal id={id} size="small" bodyClassName={classes["remove-modal-body"]}>
			<h2 className="heading-2">{t("Remove colour")}</h2>
			{t(`Are you sure you want to revert your design and remove the colour?`)}
			<Button variant="blue" type="button" onClick={onConfirm}>
				{t("Remove colour")}
			</Button>
		</Modal>
	);
}

export default RemoveColourModal;
