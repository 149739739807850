import SliderInput from "~/components/slider-input.tsx";
import { useTranslator } from "~/i18n/context.tsx";
import type { ConfirmSheetProps } from "~/components/sheet/confirm-sheet.tsx";
import ConfirmSheet from "~/components/sheet/confirm-sheet.tsx";
import { usePicture } from "../context.tsx";

function SaturationSheet(
	props: Pick<ConfirmSheetProps, "open" | "onDone" | "onCancel">,
) {
	const t = useTranslator();
	const { picture, patchPictureDebounced } = usePicture();

	return (
		<ConfirmSheet title={t("Saturation")} {...props}>
			<form onSubmit={(e) => e.preventDefault()}>
				<SliderInput
					min={0}
					max={100}
					step={1}
					value={Math.round(picture.saturation * 100)}
					onChange={(newValue) =>
						patchPictureDebounced({
							saturation: newValue / 100,
						})
					}
				/>
			</form>
		</ConfirmSheet>
	);
}

export default SaturationSheet;
