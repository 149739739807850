import { useState } from "preact/hooks";
import { pick } from "lodash-es";
import { ensureExhaustive } from "@dhau/lang-extras";
import type { SetupConfig } from "./config.ts";
import EnterCode from "./enter-code/enter-code.tsx";
import EnterNumberOfKits from "./enter-number-of-kits/enter-number-of-kits.tsx";
import UploadImage from "./upload/upload-image.tsx";
import ImageConfirm from "./image-confirm/image-confirm.tsx";
import rotateSourceImage from "./rotate-source-image.ts";

type EnterCodeStep = {
	readonly type: "enter-code";
};

type NumberOfKitsStep = {
	readonly type: "number-of-kits";
	readonly progress: Pick<SetupConfig, "code">;
};

type UploadImageStep = {
	readonly type: "upload-image";
	readonly progress: Pick<SetupConfig, "code" | "numberOfKits">;
};

type ImageConfirmStep = {
	readonly type: "image-confirm";
	readonly progress: Pick<SetupConfig, "code" | "numberOfKits" | "sourceImage">;
};

type SetupFullProps = {
	readonly onComplete: (config: SetupConfig) => void;
	readonly onCancel: () => void;
};

function SetupFull({ onComplete, onCancel }: SetupFullProps) {
	const [step, setStep] = useState<
		EnterCodeStep | NumberOfKitsStep | UploadImageStep | ImageConfirmStep
	>({
		type: "enter-code",
	});
	switch (step.type) {
		case "enter-code":
			return (
				<EnterCode
					onComplete={(code) =>
						setStep({ type: "number-of-kits", progress: { code } })
					}
					onCancel={onCancel}
				/>
			);
		case "number-of-kits":
			return (
				<EnterNumberOfKits
					onComplete={(numberOfKits) =>
						setStep({
							type: "upload-image",
							progress: { ...step.progress, numberOfKits },
						})
					}
					onCancel={() => setStep({ type: "enter-code" })}
				/>
			);
		case "upload-image":
			return (
				<UploadImage
					onComplete={(sourceImage) =>
						setStep({
							type: "image-confirm",
							progress: { ...step.progress, sourceImage },
						})
					}
					onCancel={() =>
						setStep({
							type: "number-of-kits",
							progress: pick(step.progress, "code"),
						})
					}
				/>
			);
		case "image-confirm":
			return (
				<ImageConfirm
					sourceImage={step.progress.sourceImage}
					onComplete={async (rotation) =>
						onComplete({
							...step.progress,
							sourceImage: await rotateSourceImage(
								step.progress.sourceImage,
								rotation,
							),
						})
					}
					onCancel={() =>
						setStep({
							type: "upload-image",
							progress: pick(step.progress, "code", "numberOfKits"),
						})
					}
				/>
			);
		default:
			ensureExhaustive(step);
	}
}

export default SetupFull;
