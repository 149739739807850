import type { ReactNode } from "preact/compat";
import type { JSX } from "preact/jsx-runtime";
import { ensureExhaustive } from "@dhau/lang-extras";
import { clsx } from "clsx";
import classes from "./button.module.css";

type BaseButtonProps = {
	readonly variant:
		| "blue"
		| "red"
		| "secondary"
		| "text"
		| "text-danger"
		| "link"
		| "bubble"
		| "black-line-blue-text"
		| "black-line";
	readonly children: ReactNode | readonly ReactNode[];
	readonly fullWidth?: boolean;
	readonly disabled?: boolean;
	readonly working?: boolean;
	readonly className?: string | false;
	readonly "aria-label"?: string;
};

type GeneralButtonProps = BaseButtonProps & {
	readonly type: "button";
	readonly onClick: (event: JSX.TargetedMouseEvent<HTMLButtonElement>) => void;
};

type LinkButtonProps = BaseButtonProps & {
	readonly href: string;
	readonly newWindow?: boolean;
};

type CommandButtonProps = BaseButtonProps & {
	readonly type: "button";
	readonly commandfor: string;
	readonly command: "show-modal" | "close";
};

type SubmitButtonProps = BaseButtonProps & {
	readonly type: "submit";
	readonly form?: string;
};

type ButtonProps =
	| SubmitButtonProps
	| CommandButtonProps
	| GeneralButtonProps
	| LinkButtonProps;

function Button({
	variant,
	disabled,
	working,
	children,
	fullWidth,
	className,
	"aria-label": ariaLabel,
	...rest
}: ButtonProps) {
	const commonAttrs = {
		"aria-label": ariaLabel,
		className: clsx(
			classes.btn,
			classes[`btn-${variant}`],
			fullWidth && classes["btn-full-width"],
			working && classes["btn-working"],
			className,
		),
	};

	if (!("type" in rest)) {
		if (disabled) {
			return (
				<span aria-disabled={disabled} {...commonAttrs}>
					{children}
				</span>
			);
		}

		return (
			<a
				href={rest.href}
				target={rest.newWindow ? "_blank" : undefined}
				rel={rest.newWindow ? "noopener noreferrer" : undefined}
				aria-disabled={disabled}
				{...commonAttrs}
			>
				{children}
			</a>
		);
	}

	if (rest.type === "submit") {
		return (
			<button
				type="submit"
				disabled={disabled}
				form={rest.form}
				{...commonAttrs}
			>
				{children}
			</button>
		);
	}

	if (rest.type === "button" && "onClick" in rest) {
		return (
			<button
				type="button"
				onClick={rest.onClick}
				disabled={disabled}
				{...commonAttrs}
			>
				{children}
			</button>
		);
	}

	if (rest.type === "button" && "command" in rest) {
		return (
			<button
				type="button"
				command={rest.command}
				commandfor={rest.commandfor}
				disabled={disabled}
				{...commonAttrs}
			>
				{children}
			</button>
		);
	}

	ensureExhaustive(rest);
}

export type { ButtonProps, SubmitButtonProps };
export default Button;
