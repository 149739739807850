import { useRef } from "preact/hooks";
import Button from "~/components/button.tsx";
import type { ModalProps } from "~/components/modal.tsx";
import Modal from "~/components/modal.tsx";
import { useTranslator } from "~/i18n/context.tsx";
import ResetIcon from "~/components/icons/reset.tsx";
import UploadIcon from "../setup/image-confirm/upload-icon.tsx";
import classes from "./confirm-restart-modal.module.css";

type ConfirmRestartModalProps = Pick<ModalProps, "id"> & {
	readonly onReset: () => void;
	readonly onBackToUpload: () => void;
};

function ConfirmRestartModal({
	id,
	onReset,
	onBackToUpload,
}: ConfirmRestartModalProps) {
	const t = useTranslator();
	const dialogRef = useRef<HTMLDialogElement>(null);
	return (
		<Modal id={id} size="small" dialogRef={dialogRef}>
			<div className={classes["buttons"]}>
				<Button
					type="button"
					onClick={() => {
						dialogRef.current?.close();
						onReset();
					}}
					variant="blue"
				>
					<ResetIcon />
					{t("Reset design")}
				</Button>
				<Button
					type="button"
					onClick={() => {
						dialogRef.current?.close();
						onBackToUpload();
					}}
					variant="black-line"
				>
					<UploadIcon />
					{t("Back to upload")}
				</Button>
			</div>
		</Modal>
	);
}

export default ConfirmRestartModal;
