function DownIcon() {
	return (
		<svg
			width="26"
			height="26"
			viewBox="0 0 26 26"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle cx="13" cy="13" r="13" fill="#F7B907" />
			<path
				d="M9.94 11.53L13 14.5834L16.06 11.53L17 12.47L13 16.47L9 12.47L9.94 11.53Z"
				fill="#3C3C3C"
			/>
		</svg>
	);
}

export default DownIcon;
