function HandIcon() {
	return (
		<svg
			width="28"
			height="36"
			viewBox="0 0 28 36"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M19.0492 35.0303C18.9868 35.0303 18.9244 35.0207 18.862 35.0063C18.4778 34.9054 18.2473 34.5069 18.353 34.1227L19.0877 31.3856C19.1261 31.2512 19.1981 31.1263 19.3037 31.0351L23.4478 27.3616L26.449 16.1683L16.5378 10.1179C16.1969 9.91142 16.0913 9.46965 16.2977 9.12871C16.5042 8.78778 16.946 8.68213 17.2869 8.88862L27.6591 15.2223C27.9328 15.3904 28.0624 15.7169 27.9808 16.0243L24.7875 27.9474C24.7491 28.0819 24.6771 28.2067 24.5714 28.298L20.4274 31.9715L19.7503 34.4973C19.6639 34.819 19.371 35.0303 19.054 35.0303H19.0492Z"
				fill="#3C3C3C"
			/>
			<path
				d="M18.7323 17.6715C18.6699 17.6715 18.6074 17.6619 18.545 17.6475C18.1609 17.5467 17.9304 17.1481 18.036 16.764L19.2797 12.1253C19.3805 11.7412 19.7791 11.5155 20.1633 11.6163C20.5474 11.7171 20.7779 12.1157 20.6723 12.4999L19.4286 17.1385C19.3421 17.4602 19.0492 17.6715 18.7323 17.6715Z"
				fill="#3C3C3C"
			/>
			<path
				d="M22.2761 19.8371C22.2137 19.8371 22.1513 19.8275 22.0888 19.8131C21.7047 19.7123 21.479 19.3137 21.5798 18.9296L22.8139 14.3149C22.9148 13.9308 23.3133 13.7003 23.6975 13.8059C24.0816 13.9068 24.3073 14.3053 24.2065 14.6895L22.9724 19.3041C22.886 19.6259 22.593 19.8371 22.2761 19.8371Z"
				fill="#3C3C3C"
			/>
			<path
				d="M5.69027 31.4529C5.62785 31.4529 5.56542 31.4433 5.503 31.4289C5.11884 31.3281 4.89315 30.9295 4.99399 30.5454L5.65666 28.0676L0.336131 16.9896C-0.0528248 16.1828 -0.105646 15.2705 0.192073 14.4253C0.489792 13.5802 1.09483 12.8983 1.90636 12.5094L2.34814 12.2981C4.0192 11.4962 6.02641 12.202 6.82833 13.8683L7.84153 15.9619C8.55222 16.058 9.2485 15.6114 9.44057 14.8959L12.8115 2.31008C13.0276 1.50336 13.5462 0.831091 14.2665 0.413324C14.9868 0.000358429 15.8271 -0.105284 16.6339 0.106001C17.4406 0.322087 18.1128 0.835893 18.5306 1.56098C18.9484 2.28127 19.0588 3.12641 18.8427 3.92833L15.8848 14.9727C15.7839 15.3569 15.3854 15.5874 15.0012 15.4818C14.617 15.3809 14.3914 14.9824 14.4922 14.5982L17.4502 3.55378C17.5654 3.12161 17.5078 2.66542 17.2821 2.27647C17.0564 1.88751 16.6963 1.609 16.2641 1.49376C15.8319 1.37851 15.3757 1.43613 14.9868 1.66182C14.5978 1.88751 14.3193 2.24766 14.2041 2.67983L10.8331 15.2657C10.4202 16.8071 8.83073 17.7242 7.28931 17.3113L7.15966 17.2777C6.95798 17.2248 6.78991 17.0856 6.69868 16.8935L5.53181 14.4878C5.07082 13.5322 3.92317 13.1336 2.97239 13.5898L2.53061 13.8011C2.06962 14.022 1.72389 14.4109 1.55102 14.8959C1.37815 15.3809 1.41176 15.8995 1.63265 16.3605L7.07323 27.6786C7.15006 27.8323 7.16446 28.01 7.12124 28.178L6.38655 30.9151C6.30012 31.2369 6.0072 31.4481 5.69027 31.4481V31.4529Z"
				fill="#3C3C3C"
			/>
		</svg>
	);
}

export default HandIcon;
