function ResetIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
		>
			<path
				d="M5.38793 4.26714C6.76724 3.06024 8.41954 2.44243 10.3448 2.41369C12.5 2.47116 14.2816 3.21829 15.6897 4.65507C17.1264 6.06312 17.8736 7.84473 17.931 9.9999C17.8736 12.1551 17.1264 13.9367 15.6897 15.3447C14.2816 16.7815 12.5 17.5286 10.3448 17.5861C8.59195 17.5574 7.06897 17.0545 5.77586 16.0775C5.25862 15.7327 4.78448 15.8045 4.35345 16.293C4.00862 16.8102 4.08046 17.2844 4.56897 17.7154C6.2069 18.9798 8.13218 19.6263 10.3448 19.6551C12.1552 19.6263 13.7787 19.1809 15.2155 18.3189C16.681 17.4855 17.8305 16.3361 18.6638 14.8706C19.5259 13.4338 19.9713 11.8102 20 9.9999C19.9713 8.18955 19.5259 6.56599 18.6638 5.12921C17.8305 3.66369 16.681 2.51427 15.2155 1.68093C13.7787 0.818864 12.1552 0.373462 10.3448 0.344727C7.81609 0.402198 5.67529 1.22116 3.92241 2.80162L2.58621 1.46542C2.29885 1.17806 1.93966 1.03438 1.50862 1.03438C1.07759 1.03438 0.718391 1.17806 0.431035 1.46542C0.143678 1.75277 0 2.11197 0 2.543V7.5861C0.0574713 8.21829 0.402299 8.56312 1.03448 8.62059H6.07759C6.50862 8.62059 6.86782 8.47691 7.15517 8.18955C7.44253 7.9022 7.58621 7.543 7.58621 7.11197C7.58621 6.68093 7.44253 6.32174 7.15517 6.03438L5.38793 4.26714ZM2.06897 3.87921L4.74138 6.55162H2.06897V3.87921Z"
				fill="currentColor"
			/>
		</svg>
	);
}

export default ResetIcon;
