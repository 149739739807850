import { clsx } from "clsx";
import type { InputHTMLAttributes } from "preact/compat";
import type { FormFieldProps } from "./form-field.tsx";
import FormField from "./form-field.tsx";
import sharedClasses from "./inputs-shared.module.css";
import classes from "./single-select-field.module.css";

type Option = {
	readonly value: string;
	readonly label: string;
};

type SingleSelectFieldProps = Pick<FormFieldProps, "label" | "className"> & {
	readonly name: string;
	readonly options: readonly Option[];
} & Pick<
		InputHTMLAttributes<HTMLSelectElement>,
		"placeholder" | "disabled" | "required"
	>;

function SingleSelectField({
	label,
	name,
	options,
	className,
	placeholder,
	...rest
}: SingleSelectFieldProps) {
	const fieldId = `${name}-field`;
	return (
		<FormField fieldId={fieldId} label={label} className={className}>
			<select
				id={fieldId}
				name={name}
				className={clsx(sharedClasses["input"], classes["select"])}
				{...rest}
			>
				{placeholder && <option value="">{placeholder}</option>}
				{options.map((o) => (
					<option key={o.value} value={o.value}>
						{o.label}
					</option>
				))}
			</select>
		</FormField>
	);
}

export default SingleSelectField;
