function BasketIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="39"
			height="39"
			viewBox="0 0 39 39"
			fill="none"
		>
			<path
				d="M0.8125 15.4375V20.3125H2.4375L7.3125 34.9375H31.6875L36.5625 20.3125H38.1875V15.4375H0.8125Z"
				fill="#E83044"
			/>
			<path
				d="M35.4786 23.5625L36.5625 20.3125H2.4375L3.52138 23.5625H35.4786Z"
				fill="#FE5B6D"
			/>
			<path
				d="M24.375 31.6875L27.625 23.5625"
				stroke="#3C3C3C"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M17.875 31.6875L21.125 23.5625"
				stroke="#3C3C3C"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M11.375 31.6875L14.625 23.5625"
				stroke="#3C3C3C"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M5.6875 15.4375L15.4375 4.0625"
				stroke="#3C3C3C"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M34.125 15.4375L23.5625 4.0625"
				stroke="#3C3C3C"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M36.5625 20.3125H2.4375L7.3125 34.9375H31.6875L36.5625 20.3125Z"
				stroke="#3C3C3C"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M38.1875 15.4375H0.8125V20.3125H38.1875V15.4375Z"
				stroke="#3C3C3C"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
}

export default BasketIcon;
