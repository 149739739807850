import { usePinch } from "@use-gesture/react";
import { useRef, useState, useEffect } from "preact/hooks";
import { maxImageZoom } from "@brickme/project-core/src/model/picture.ts";
import { usePicture } from "../context.tsx";

const onPreventDefault = (e: Event) => e.preventDefault();

function useImageZoomPinch() {
	const startPinchRef = useRef<{ zoom: number; distance: number }>();
	const [isPinching, setPinching] = useState(false);
	const {
		picture: { imageZoom },
		patchPicture,
	} = usePicture();

	useEffect(() => {
		document.addEventListener("gesturestart", onPreventDefault);
		document.addEventListener("gesturechange", onPreventDefault);
		return () => {
			document.removeEventListener("gesturestart", onPreventDefault);
			document.removeEventListener("gesturechange", onPreventDefault);
		};
	});

	const canvasRef = useRef<HTMLCanvasElement>(null);
	usePinch(
		(e) => {
			setPinching(!!e.pinching);
			if (!e.pinching) {
				startPinchRef.current = undefined;
				return;
			}

			const [currentPinchDistance] = e.da;

			if (startPinchRef.current === undefined) {
				startPinchRef.current = {
					zoom: imageZoom,
					distance: currentPinchDistance,
				};
				return;
			}

			// current.distance can be 0
			const initialDistance =
				startPinchRef.current.distance === 0
					? Math.max(currentPinchDistance, 0.1)
					: startPinchRef.current.distance;
			const differenceRatio = currentPinchDistance / initialDistance;
			const newImageZoom = Math.max(
				1,
				Math.min(maxImageZoom, startPinchRef.current.zoom * differenceRatio),
			);
			// For performance reasons - prevent a bunch of re-builds and re-renders
			if (Math.abs(imageZoom - newImageZoom) < 0.1) {
				return;
			}

			patchPicture({ imageZoom: newImageZoom });
		},
		{ target: canvasRef },
	);

	return {
		isPinching,
		imageZoomPinchCanvasRef: canvasRef,
	};
}

export default useImageZoomPinch;
