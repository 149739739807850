import {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "preact/hooks";

function useLocalStorage<T>(
	key: string,
	defaultValue: T,
	parse: (raw: string) => T,
	stringify: (value: T) => string,
): [T, (value: T) => void] {
	const defaultValueRef = useRef(defaultValue);
	defaultValueRef.current = defaultValue;
	const parseRef = useRef(parse);
	parseRef.current = parse;
	const stringifyRef = useRef(stringify);
	stringifyRef.current = stringify;

	const extractValue = useCallback(() => {
		const rawValue = localStorage.getItem(key);

		if (rawValue === null) {
			return defaultValueRef.current;
		}

		return parseRef.current(rawValue);
	}, [key, defaultValueRef, stringifyRef, parseRef]);
	const [value, setValue] = useState<T>(extractValue);

	useEffect(() => {
		const value = extractValue();
		setValue(value);
	}, [extractValue]);

	return useMemo(
		() => [
			value,
			(newValue: T) => {
				setValue(newValue);

				if (newValue === defaultValueRef.current) {
					localStorage.removeItem(key);
					return;
				}

				localStorage.setItem(key, stringify(newValue));
			},
		],
		[value, key, defaultValueRef],
	);
}

export default useLocalStorage;
