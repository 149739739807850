function RotateIcon() {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				id="Vector"
				d="M20.1 8.55005C19.4 6.95005 18.3333 5.66672 16.9 4.70005C15.4667 3.73338 13.8333 3.23338 12 3.20005C9.93333 3.23338 8.16667 3.96672 6.7 5.40005L6.5 5.60005L8.3 7.40005C8.63333 7.73338 8.8 8.15005 8.8 8.65005C8.8 9.15005 8.63333 9.56671 8.3 9.90005C7.96667 10.2334 7.55 10.4 7.05 10.4H1.2C0.466667 10.3334 0.0666667 9.93338 0 9.20005V3.35005C0 2.85005 0.166667 2.43338 0.5 2.10005C0.833333 1.76672 1.25 1.60005 1.75 1.60005C2.25 1.60005 2.66667 1.76672 3 2.10005L4.8 3.90005L5 3.70005C6.93333 1.80005 9.26667 0.833382 12 0.800049C14.3333 0.833382 16.4167 1.46672 18.25 2.70005C20.0833 3.93338 21.4333 5.56672 22.3 7.60005C22.5333 8.30005 22.3333 8.83338 21.7 9.20005C21 9.40005 20.4667 9.18338 20.1 8.55005ZM1.7 16.4C1.46667 15.7 1.68333 15.1834 2.35 14.85C3.05 14.6167 3.56667 14.8167 3.9 15.45C4.6 17.05 5.66667 18.3334 7.1 19.3C8.53333 20.2667 10.1667 20.7667 12 20.8C14.0667 20.7667 15.8333 20.0334 17.3 18.6L17.5 18.4L15.7 16.6C15.3667 16.2667 15.2 15.85 15.2 15.35C15.2 14.85 15.3667 14.4334 15.7 14.1C16.0333 13.7667 16.45 13.6 16.95 13.6H22.8C23.5333 13.6667 23.9333 14.0667 24 14.8V20.65C24 21.15 23.8333 21.5667 23.5 21.9C23.1667 22.2334 22.75 22.4 22.25 22.4C21.75 22.4 21.3333 22.2334 21 21.9L19.2 20.1L19 20.3C17.0333 22.2 14.7 23.1667 12 23.2C9.66667 23.1667 7.58333 22.5334 5.75 21.3C3.95 20.0667 2.6 18.4334 1.7 16.4ZM2.4 8.00005H5.5L2.4 4.90005V8.00005ZM21.6 16H18.5L21.6 19.1V16Z"
				fill="currentColor"
			/>
		</svg>
	);
}

export default RotateIcon;
