import { useApiClient } from "~/api/context.tsx";
import SimplePage from "~/components/simple-page.tsx";
import type { FormValues } from "~/hooks/use-form.ts";
import useForm from "~/hooks/use-form.ts";
import { useTranslator } from "~/i18n/context.tsx";
import SimpleFormCard from "~/components/simple-form-card.tsx";
import ChatEnhancedIcon from "~/components/icons/chat-enhanced.tsx";
import type {
	CheckPremadeKitCodeAvailabilityQuery,
	CheckPremadeKitCodeAvailabilityQueryVariables,
} from "./check-premade-kit-code-availability-query.generated.ts";
import checkPremadeKitCodeAvailabilityQuery from "./check-premade-kit-code-availability-query.graphql";
import classes from "./enter-code.module.css";

type EnterCodeProps = {
	readonly onComplete: (code: string) => void;
	readonly onCancel: () => void;
};

function EnterCode({ onComplete, onCancel }: EnterCodeProps) {
	const t = useTranslator();
	const apiClient = useApiClient();
	const form = useForm({
		onSubmit: async (values: FormValues) => {
			const code = values.code as string;
			const { checkPremadeKitCodeAvailability } = await apiClient.query<
				CheckPremadeKitCodeAvailabilityQuery,
				CheckPremadeKitCodeAvailabilityQueryVariables
			>(checkPremadeKitCodeAvailabilityQuery, {
				code,
			});
			if (checkPremadeKitCodeAvailability === "DOESNT_EXIST") {
				throw new Error(t("Code doesn't exist"));
			}
			if (checkPremadeKitCodeAvailability === "USED") {
				throw new Error(t("Code has been used too many times"));
			}
			onComplete(code);
		},
	});
	const { isWorking } = form;

	return (
		<SimplePage onBackClick={onCancel} centerContent>
			<SimpleFormCard
				form={form}
				icon={<ChatEnhancedIcon />}
				heading={t("Enter your code")}
				summary={t(
					`Your kit came with a unique 6-digit code, found on the first page of the instructions manual. Please enter the code below.`,
				)}
				submitLabel={t("Next")}
			>
				<input
					type="text"
					name="code"
					disabled={isWorking}
					minLength={6}
					maxLength={6}
					required
					className={classes["code-input"]}
				/>
			</SimpleFormCard>
		</SimplePage>
	);
}

export default EnterCode;
