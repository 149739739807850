import { clsx } from "clsx";
import type { ReactNode } from "preact/compat";
import classes from "./form-field.module.css";

type FormFieldProps = {
	readonly label?: string;
	readonly className?: string;
	readonly fieldId: string;
	readonly children: ReactNode;
};

function FormField({ label, className, fieldId, children }: FormFieldProps) {
	return (
		<div className={clsx(classes["form-field"], className)}>
			{label && (
				<label htmlFor={fieldId} className={classes["form-field-label"]}>
					{label}
				</label>
			)}
			{children}
		</div>
	);
}

export type { FormFieldProps };
export default FormField;
