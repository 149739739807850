import { backOff } from "exponential-backoff";
import { print, type DocumentNode } from "graphql";

type RequestOptions = {
	readonly signal?: AbortSignal;
};

function createApiClient(url: string) {
	async function performRequest<
		TResult,
		TVariables extends Record<string, unknown>,
	>(
		query: DocumentNode,
		variables: TVariables | undefined,
		options?: RequestOptions,
	) {
		const response = await fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				query: print(query),
				variables,
			}),
			signal: options?.signal,
		});

		const { data, errors } = await response.json();

		if (errors) {
			throw new Error(errors.map((e: any) => e.message).join("\n"));
		}

		return data as TResult;
	}

	async function query<TQuery, TVariables extends Record<string, unknown>>(
		query: DocumentNode,
		variables: TVariables,
		options?: RequestOptions,
	): Promise<TQuery>;

	async function query<TQuery>(
		query: DocumentNode,
		variables?: undefined,
		options?: RequestOptions,
	): Promise<TQuery>;

	async function query<TQuery, TVariables extends Record<string, unknown>>(
		query: DocumentNode,
		variables?: TVariables,
		options?: RequestOptions,
	): Promise<TQuery> {
		return backOff(
			() => performRequest<TQuery, TVariables>(query, variables, options),
			{
				jitter: "none",
				maxDelay: 10_000,
				numOfAttempts: 8,
				startingDelay: 100,
				timeMultiple: 2,
			},
		);
	}

	async function mutate<TMutation, TVariables extends Record<string, unknown>>(
		mutation: DocumentNode,
		variables: TVariables,
		options?: RequestOptions,
	): Promise<TMutation>;

	async function mutate<TMutation>(
		mutation: DocumentNode,
		variables?: undefined,
		options?: RequestOptions,
	): Promise<TMutation>;

	async function mutate<TMutation, TVariables extends Record<string, unknown>>(
		mutation: DocumentNode,
		variables?: TVariables,
		options?: RequestOptions,
	): Promise<TMutation> {
		return performRequest<TMutation, TVariables>(mutation, variables, options);
	}

	return {
		query,
		mutate,
	};
}

type ApiClient = ReturnType<typeof createApiClient>;

export type { ApiClient };
export { createApiClient };
