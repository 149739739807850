function BasketLineIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="20"
			height="18"
			viewBox="0 0 20 18"
			fill="none"
		>
			<path
				d="M8.4375 0.319441C8.87731 0.597218 8.99306 0.967589 8.78472 1.43055L5.97222 6.88194H14.0278L11.2153 1.43055C11.0069 0.967589 11.1227 0.597218 11.5625 0.319441C12.0255 0.111107 12.3958 0.226848 12.6736 0.666663L15.9028 6.88194H17.1875H19.1667C19.6759 6.92824 19.9537 7.20602 20 7.71527C19.9537 8.22453 19.6759 8.50231 19.1667 8.54861H18.4722L16.5278 16.3264C16.3889 16.8125 16.1227 17.206 15.7292 17.5069C15.3588 17.831 14.9074 17.9931 14.375 17.9931H5.625C5.09259 17.9931 4.6412 17.831 4.27083 17.5069C3.87732 17.206 3.61111 16.8125 3.47222 16.3264L1.52778 8.54861H0.833333C0.324074 8.50231 0.0462963 8.22453 0 7.71527C0.0462963 7.20602 0.324074 6.92824 0.833333 6.88194H1.11111H4.09722L7.32639 0.666663C7.60417 0.226848 7.97454 0.111107 8.4375 0.319441ZM3.26389 8.54861L5.10417 15.9097C5.17361 16.1643 5.34722 16.3032 5.625 16.3264H14.375C14.6528 16.3032 14.838 16.1643 14.9306 15.9097L16.7708 8.54861H3.26389ZM7.77778 11.0486V13.8264C7.73148 14.3356 7.4537 14.6134 6.94444 14.6597C6.43518 14.6134 6.15741 14.3356 6.11111 13.8264V11.0486C6.15741 10.5393 6.43518 10.2616 6.94444 10.2153C7.4537 10.2616 7.73148 10.5393 7.77778 11.0486ZM10 10.2153C10.5093 10.2616 10.787 10.5393 10.8333 11.0486V13.8264C10.787 14.3356 10.5093 14.6134 10 14.6597C9.49074 14.6134 9.21296 14.3356 9.16667 13.8264V11.0486C9.21296 10.5393 9.49074 10.2616 10 10.2153ZM13.8889 11.0486V13.8264C13.8426 14.3356 13.5648 14.6134 13.0556 14.6597C12.5463 14.6134 12.2685 14.3356 12.2222 13.8264V11.0486C12.2685 10.5393 12.5463 10.2616 13.0556 10.2153C13.5648 10.2616 13.8426 10.5393 13.8889 11.0486Z"
				fill="currentColor"
			/>
		</svg>
	);
}

export default BasketLineIcon;
