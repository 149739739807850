import { clsx } from "clsx";
import type { ReactNode } from "preact/compat";
import { useTranslator } from "~/i18n/context.tsx";
import Button from "./button.tsx";
import ArrowLeftIcon from "./icons/arrow-left.tsx";
import classes from "./simple-page.module.css";

type SimplePageProps = {
	readonly onBackClick: (() => void) | undefined;
	readonly bottomSheet?: ReactNode;
	readonly centerContent?: boolean;
	readonly children: ReactNode;
};

function SimplePage({
	onBackClick,
	centerContent,
	bottomSheet,
	children,
}: SimplePageProps) {
	const t = useTranslator();
	return (
		<div className={classes["simple-page-container"]}>
			<div className={classes["simple-page"]}>
				{onBackClick !== undefined && (
					<Button
						type="button"
						variant="link"
						onClick={onBackClick}
						className={classes["back-button"]}
					>
						<ArrowLeftIcon size={16} /> {t("Back")}
					</Button>
				)}
				<div
					className={clsx(
						classes["simple-page-content"],
						centerContent && classes["simple-page-content-centered"],
					)}
				>
					{children}
				</div>
			</div>
			{bottomSheet && (
				<div className={classes["simple-page-bottom"]}>{bottomSheet}</div>
			)}
		</div>
	);
}

export default SimplePage;
