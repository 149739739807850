import { useMemo } from "preact/hooks";
import type { ModalProps } from "~/components/modal.tsx";
import Modal from "~/components/modal.tsx";
import { useTranslator } from "~/i18n/context.tsx";
import { getColourBricksToBuy } from "~/utils/colour-bricks.ts";
import { useShopify } from "~/shopify-api/context.tsx";
import Button from "~/components/button.tsx";
import { useCurrentBrickedBuild, usePalettes } from "../context.tsx";
import classes from "./buy-colour-bricks-modal.module.css";

type BuyColourBricksModalProps = Pick<ModalProps, "id">;

function BuyColourBricksModal({ id }: BuyColourBricksModalProps) {
	const t = useTranslator();
	const build = useCurrentBrickedBuild();
	const { kitsPalette, kitPlusExtrasPalette } = usePalettes();
	const brickCounts = build?.brickCounts;
	const { brickProducts } = useShopify();
	const colourBricksRequired = getColourBricksToBuy(
		brickCounts,
		brickProducts,
		kitsPalette,
		kitPlusExtrasPalette,
	);
	const addToCartPath = useMemo(() => {
		const params = new URLSearchParams();
		for (const { shopifyVariant, packCount } of colourBricksRequired) {
			if (shopifyVariant) {
				params.append(
					"addProductItem",
					JSON.stringify({
						id: shopifyVariant.id,
						quantity: packCount,
					}),
				);
			}
		}
		return `/pages/add-to-cart/?${params.toString()}`;
	}, []);
	// eslint-disable-next-line no-console
	console.log("TODO: Proper website url");
	const addToCartUrl = "https://brick-me-app-dev.myshopify.com" + addToCartPath;
	return (
		<Modal
			id={id}
			size="small"
			bodyClassName={classes["buy-colour-modal-body"]}
		>
			{colourBricksRequired.length > 0 ? (
				<>
					<div>
						{t(`You have {number} extra colour(s)`, {
							number: colourBricksRequired.length,
						})}
					</div>
					<Button href={addToCartUrl} newWindow variant="blue">
						{t("Add to cart")}
					</Button>
				</>
			) : (
				<>
					{t(`You have all the colours you need for this design in your kit.`)}
				</>
			)}
		</Modal>
	);
}

export default BuyColourBricksModal;
