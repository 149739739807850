function ZoomInIcon() {
	return (
		<svg
			width="52"
			height="52"
			viewBox="0 0 52 52"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M34.1358 30.8861H32.4242L31.8175 30.3011C33.9408 27.8311 35.2192 24.6244 35.2192 21.1361C35.2192 13.3577 28.9142 7.05273 21.1358 7.05273C13.3575 7.05273 7.05249 13.3577 7.05249 21.1361C7.05249 28.9144 13.3575 35.2194 21.1358 35.2194C24.6242 35.2194 27.8308 33.9411 30.3008 31.8177L30.8858 32.4244V34.1361L41.7192 44.9477L44.9475 41.7194L34.1358 30.8861ZM21.1358 30.8861C15.7408 30.8861 11.3858 26.5311 11.3858 21.1361C11.3858 15.7411 15.7408 11.3861 21.1358 11.3861C26.5308 11.3861 30.8858 15.7411 30.8858 21.1361C30.8858 26.5311 26.5308 30.8861 21.1358 30.8861ZM22.2192 15.7194H20.0525V20.0527H15.7192V22.2194H20.0525V26.5527H22.2192V22.2194H26.5525V20.0527H22.2192V15.7194Z"
				fill="white"
			/>
		</svg>
	);
}

export default ZoomInIcon;
