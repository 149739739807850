function UploadImageIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="95"
			height="96"
			viewBox="0 0 95 96"
			fill="none"
		>
			<g opacity="0.15">
				<path
					d="M88.4881 15.1863H8.62061V95.0538H88.4881V15.1863Z"
					fill="#0F6DDA"
				/>
				<path
					d="M93.1083 29.6952L80.7114 36.5134L68.3142 29.6952V15.7486L80.7114 8.62036L93.1083 15.7486V29.6952Z"
					fill="#0F6DDA"
					stroke="#0F6DDA"
					stroke-width="2"
					stroke-miterlimit="10"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
			</g>
			<path d="M80.8675 7.56592H1V87.4334H80.8675V7.56592Z" fill="#F0F1F5" />
			<path
				d="M59.5693 47.4995L47.5894 67.4662L36.4969 63.0291L32.0598 69.6847H67.5561L59.5693 47.4995Z"
				fill="#0F6DDA"
			/>
			<path
				d="M36.4966 28.8635V37.7376L27.6226 43.0624L18.748 37.7376L36.4966 28.8635Z"
				fill="#F7B806"
			/>
			<path
				d="M36.4966 37.7375L27.6225 43.0623L18.748 37.7375V28.8634L27.6225 23.0952L36.4966 28.8634V37.7375Z"
				fill="#F7B806"
				fill-opacity="0.19"
			/>
			<path
				d="M36.4966 37.7375L27.6225 43.0623L18.748 37.7375V28.8634L27.6225 23.0952L36.4966 28.8634V37.7375Z"
				fill="#F7B806"
				fill-opacity="0.19"
			/>
			<path
				d="M36.4966 37.7375L27.6225 43.0623L18.748 37.7375V28.8634L27.6225 23.0952L36.4966 28.8634V37.7375Z"
				stroke="#092F63"
				stroke-width="2"
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M58.0725 7.56592H1V87.4334H80.8675V32.4061"
				stroke="#092F63"
				stroke-width="2"
				stroke-miterlimit="10"
				stroke-linejoin="round"
			/>
			<path
				d="M1 69.6848H80.8675"
				stroke="#092F63"
				stroke-width="2"
				stroke-miterlimit="10"
				stroke-linejoin="round"
			/>
			<path
				d="M56.463 38.6252L43.1519 60.8104L32.0593 56.3734L23.1848 69.6845H67.5556L56.463 38.6252Z"
				fill="#0F6DDA"
				fill-opacity="0.15"
				stroke="#092F63"
				stroke-width="2"
				stroke-miterlimit="10"
				stroke-linejoin="round"
			/>
			<path
				d="M88.652 22.0748L76.2551 28.8931L63.8579 22.0748V8.12829L76.2551 1L88.652 8.12829V22.0748Z"
				fill="#E83044"
			/>
			<path
				d="M88.652 8.12842V22.0749L76.2551 28.8932L63.8579 22.0749L88.652 8.12842Z"
				fill="#E83044"
			/>
			<path
				d="M76.2544 8.74854V21.1455"
				stroke="white"
				stroke-width="2"
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M82.4538 14.9475H70.0569"
				stroke="white"
				stroke-width="2"
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M88.652 22.0748L76.2551 28.8931L63.8579 22.0748V8.12829L76.2551 1L88.652 8.12829V22.0748Z"
				stroke="#092F63"
				stroke-width="2"
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
}

export default UploadImageIcon;
