function GridIcon() {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M13.125 3.75V10.875H21.75V4.5C21.7188 4.03125 21.4688 3.78125 21 3.75H13.125ZM10.875 3.75H3C2.53125 3.78125 2.28125 4.03125 2.25 4.5V10.875H10.875V3.75ZM2.25 13.125V19.5C2.28125 19.9688 2.53125 20.2188 3 20.25H10.875V13.125H2.25ZM13.125 20.25H21C21.4688 20.2188 21.7188 19.9688 21.75 19.5V13.125H13.125V20.25ZM0 4.5C0.03125 3.65625 0.328125 2.95312 0.890625 2.39062C1.45312 1.82812 2.15625 1.53125 3 1.5H21C21.8438 1.53125 22.5469 1.82812 23.1094 2.39062C23.6719 2.95312 23.9688 3.65625 24 4.5V19.5C23.9688 20.3438 23.6719 21.0469 23.1094 21.6094C22.5469 22.1719 21.8438 22.4688 21 22.5H3C2.15625 22.4688 1.45312 22.1719 0.890625 21.6094C0.328125 21.0469 0.03125 20.3438 0 19.5V4.5Z"
				fill="currentColor"
			/>
		</svg>
	);
}

export default GridIcon;
