import { getEnhanceDetailOptions } from "@brickme/project-core/src";
import { isEqual } from "lodash-es";
import { useTranslator } from "~/i18n/context.tsx";
import ConfirmSheet from "~/components/sheet/confirm-sheet.tsx";
import type { ConfirmSheetProps } from "~/components/sheet/confirm-sheet.tsx";
import { usePicture } from "../context.tsx";
import classes from "./detail-sheet.module.css";

function DetailSheet(
	props: Pick<ConfirmSheetProps, "open" | "onDone" | "onCancel">,
) {
	const t = useTranslator();
	const { picture, patchPicture } = usePicture();

	return (
		<ConfirmSheet title={t("Detail")} {...props}>
			<form className={classes["form"]} onSubmit={(e) => e.preventDefault()}>
				<ol>
					{getEnhanceDetailOptions(picture.detailFilter).map((option) => {
						const fieldId = `detail-${option.label}`;
						return (
							<li key={option.label}>
								<input
									id={fieldId}
									type="radio"
									name="detail"
									value={option.label}
									onChange={(e) => {
										if (e.currentTarget.checked) {
											patchPicture({
												detailFilter: option.value,
											});
										}
									}}
									checked={isEqual(picture.detailFilter, option.value)}
								/>
								<label htmlFor={fieldId}>{option.label}</label>
							</li>
						);
					})}
				</ol>
			</form>
		</ConfirmSheet>
	);
}

export default DetailSheet;
