import type { GenericSize } from "@brickme/project-core/src/model/geom.ts";
import { useTranslator } from "~/i18n/context.tsx";
import { doesDeviceHaveTouchCapabilities } from "~/utils/browser.ts";
import ZoomInIcon from "./zoom-in-icon.tsx";
import MouseScrollIcon from "./mouse-scroll-icon.tsx";
import LeftClickMouseIcon from "./left-click-mouse-icon.tsx";
import DragFingerIcon from "./drag-finger-icon.tsx";
import MoveIcon from "./move-icon.tsx";
import PinchIcon from "./pinch-icon.tsx";
import classes from "./choose-layout-overlay.module.css";

type ChooseLayoutOverlayProps = {
	readonly size: GenericSize<number>;
};

function ChooseLayoutOverlay({ size }: ChooseLayoutOverlayProps) {
	const t = useTranslator();
	return (
		<div
			className={classes["choose-layout-overlay"]}
			style={{
				...size,
				left: `calc(50% - ${size.width / 2}px)`,
				top: `calc(50% - ${size.height / 2}px)`,
			}}
		>
			{doesDeviceHaveTouchCapabilities ? (
				<>
					<div className={classes["instruction"]}>
						<div className={classes["icon"]}>
							<PinchIcon />
							<ZoomInIcon />
						</div>
						<div>{t("Pinch to Zoom")}</div>
					</div>
					<div className={classes["instruction"]}>
						<div className={classes["icon"]}>
							<DragFingerIcon />
							<MoveIcon />
						</div>
						<div>{t("Drag to Move")}</div>
					</div>
				</>
			) : (
				<>
					<div className={classes["instruction"]}>
						<div className={classes["icon"]}>
							<MouseScrollIcon />
							<ZoomInIcon />
						</div>
						<div>{t("Scroll to Zoom")}</div>
					</div>
					<div className={classes["instruction"]}>
						<div className={classes["icon"]}>
							<LeftClickMouseIcon />
							<MoveIcon />
						</div>
						<div>{t("Drag to Move")}</div>
					</div>
				</>
			)}
		</div>
	);
}

export default ChooseLayoutOverlay;
