import type { ReactNode } from "react";
import { clsx } from "clsx";
import classes from "./bottom-sheet.module.css";

type BottomSheetProps = {
	readonly open: boolean;
	readonly children: ReactNode;
};

function BottomSheet({ children, open }: BottomSheetProps) {
	return (
		<div
			className={clsx(
				classes["bottom-sheet"],
				open && classes["bottom-sheet-open"],
			)}
		>
			{children}
		</div>
	);
}

// import {
// 	BottomSheet as BaseBottomSheet,
// 	type BottomSheetProps as BaseBottomSheetProps,
// } from "react-spring-bottom-sheet";
// import "react-spring-bottom-sheet/dist/style.css";

{
	/* <BaseBottomSheet {...props} blocking={false}>
			<div className={classes["bottom-sheet"]}>{children}</div>
		</BaseBottomSheet> */
}

export default BottomSheet;
