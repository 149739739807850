import type { HTMLAttributes } from "preact/compat";

type ChooseIconProps = Pick<HTMLAttributes<SVGSVGElement>, "className">;

function ChooseIcon({ className }: ChooseIconProps) {
	return (
		<svg
			className={className}
			width="31"
			height="31"
			viewBox="0 0 31 31"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle cx="15.6601" cy="15.2521" r="15.2118" fill="#0F6DDA" />
			<path
				d="M15.6602 4.95229C17.5418 4.95229 19.2585 5.41445 20.81 6.33878C22.3616 7.2301 23.616 8.48455 24.5733 10.1021C25.4977 11.7197 25.9598 13.4363 25.9598 15.252C25.9598 17.0676 25.4977 18.7842 24.5733 20.4018C23.616 22.0194 22.3616 23.2738 20.81 24.1651C19.2585 25.0895 17.5418 25.5516 15.6602 25.5516C13.7785 25.5516 12.0619 25.0895 10.5103 24.1651C8.95878 23.2738 7.70433 22.0194 6.74699 20.4018C5.82266 18.7842 5.36049 17.0676 5.36049 15.252C5.36049 13.4363 5.82266 11.7197 6.74699 10.1021C7.70433 8.48455 8.95878 7.2301 10.5103 6.33878C12.0619 5.41445 13.7785 4.95229 15.6602 4.95229ZM15.6602 27.9285C17.971 27.8955 20.0837 27.3343 21.9984 26.2449C23.9131 25.1225 25.4647 23.5709 26.6531 21.5902C27.7755 19.5765 28.3367 17.4638 28.3367 15.252C28.3367 13.0402 27.7755 10.9274 26.6531 8.9137C25.4647 6.93299 23.9131 5.38144 21.9984 4.25904C20.0837 3.16965 17.971 2.60845 15.6602 2.57544C13.3493 2.60845 11.2366 3.16965 9.3219 4.25904C7.40722 5.38144 5.85567 6.93299 4.66724 8.9137C3.54484 10.9274 2.98364 13.0402 2.98364 15.252C2.98364 17.4638 3.54484 19.5765 4.66724 21.5902C5.85567 23.5709 7.40722 25.1225 9.3219 26.2449C11.2366 27.3343 13.3493 27.8955 15.6602 27.9285ZM14.4717 19.6095C14.5378 20.3358 14.9339 20.7319 15.6602 20.7979C16.3864 20.7319 16.7826 20.3358 16.8486 19.6095V16.4404H20.0177C20.744 16.3744 21.1401 15.9782 21.2061 15.252C21.1401 14.5257 20.744 14.1296 20.0177 14.0635H16.8486V10.8944C16.7826 10.1681 16.3864 9.77201 15.6602 9.70598C14.9339 9.77201 14.5378 10.1681 14.4717 10.8944V14.0635H11.3026C10.5764 14.1296 10.1802 14.5257 10.1142 15.252C10.1802 15.9782 10.5764 16.3744 11.3026 16.4404H14.4717V19.6095Z"
				fill="white"
			/>
		</svg>
	);
}

export default ChooseIcon;
