import { useReducer } from "preact/hooks";
import type { PictureConfiguration } from "@brickme/project-core/src/model/picture.ts";
import { useTranslator } from "~/i18n/context.tsx";
import ColourIcon from "~/components/icons/colour-icon.tsx";
import BottomMenu from "../bottom-menu.tsx";
import { useCurrentBrickedBuild, usePicture } from "../context.tsx";
import type { Mode } from "../mode.ts";
import BrightnessIcon from "./brightness-icon.tsx";
import BrightnessSheet from "./brightness-sheet.tsx";
import ColoursSheet from "./colours-sheet.tsx";
import ContrastIcon from "./contrast-icon.tsx";
import ContrastSheet from "./contrast-sheet.tsx";
import DetailIcon from "./detail-icon.tsx";
import DetailSheet from "./detail-sheet.tsx";
import SaturationIcon from "./saturation-icon.tsx";
import SaturationSheet from "./saturation-sheet.tsx";
import RemoveBgIcon from "./remove-bg-icon.tsx";
import classes from "./adjustments-menu.module.css";

type ActiveState = {
	readonly type:
		| "detail"
		| "brightness"
		| "contrast"
		| "saturation"
		| "colours";
	readonly snapshot: PictureConfiguration;
};

type AdjustmentsMenuProps = {
	readonly mode: Mode;
	readonly hasCode: boolean;
};

function AdjustmentsMenu({ mode, hasCode }: AdjustmentsMenuProps) {
	const t = useTranslator();
	const { picture, patchPicture, setPicture } = usePicture();
	const { removeBackground } = picture;
	const bricked = useCurrentBrickedBuild();

	// Active state
	const [active, setActiveByType] = useReducer<
		ActiveState | undefined,
		ActiveState["type"] | undefined
	>((_, newType) => {
		if (newType) {
			return {
				type: newType,
				snapshot: picture,
			} as ActiveState;
		}
		return undefined;
	}, undefined);
	const onDone = () => setActiveByType(undefined);
	const onCancel = () => {
		// TODO: Reset to snapshot
		if (active === undefined) {
			return;
		}

		setPicture(active.snapshot);
		setActiveByType(undefined);
	};

	return (
		<>
			<BottomMenu
				buttons={[
					{
						id: "details",
						label: t("Detail"),
						icon: <DetailIcon />,
						onClick: () => setActiveByType("detail"),
					},
					{
						id: "contrast",
						label: t("Contrast"),
						icon: <ContrastIcon />,
						onClick: () => setActiveByType("contrast"),
					},
					{
						id: "brightness",
						label: t("Brightness"),
						icon: <BrightnessIcon />,
						onClick: () => setActiveByType("brightness"),
					},
					...(mode === "colour"
						? [
								{
									id: "saturation",
									label: t("Saturation"),
									icon: <SaturationIcon />,
									onClick: () => setActiveByType("saturation"),
								},
								{
									id: "colours",
									label: t("Colours"),
									icon: <ColourIcon />,
									onClick: () => setActiveByType("colours"),
								},
							]
						: []),
					...(hasCode
						? [
								{
									id: "remove-bg",
									label: t("Remove BG"),
									icon: (() => {
										if (!removeBackground) {
											return <RemoveBgIcon />;
										}
										if (
											bricked?.operationsMissingSources.includes(
												"Remove background",
											)
										) {
											return <div>{t("Loading")}...</div>;
										}
										return <RemoveBgIcon />;
									})(),
									onClick: () =>
										patchPicture({
											removeBackground: removeBackground
												? undefined
												: { noBrick: true },
										}),
								},
							]
						: []),
				]}
			/>
			<div className={classes["sub-sheets"]}>
				<BrightnessSheet
					open={active?.type === "brightness"}
					onDone={onDone}
					onCancel={onCancel}
				/>
				<ContrastSheet
					open={active?.type === "contrast"}
					onDone={onDone}
					onCancel={onCancel}
				/>
				{mode === "colour" && (
					<>
						<SaturationSheet
							open={active?.type === "saturation"}
							onDone={onDone}
							onCancel={onCancel}
						/>
						<ColoursSheet
							open={active?.type === "colours"}
							onDone={onDone}
							onCancel={onCancel}
						/>
					</>
				)}
				<DetailSheet
					open={active?.type === "detail"}
					onDone={onDone}
					onCancel={onCancel}
				/>
			</div>
		</>
	);
}

export default AdjustmentsMenu;
