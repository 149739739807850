import SimplePage from "~/components/simple-page.tsx";
import type { FormValues } from "~/hooks/use-form.ts";
import useForm from "~/hooks/use-form.ts";
import { useTranslator } from "~/i18n/context.tsx";
import SimpleFormCard from "~/components/simple-form-card.tsx";
import BasketIcon from "~/components/icons/basket.tsx";
import SingleSelectField from "~/components/forms/single-select-field.tsx";
import buyLocationOptions from "./buy-location-options.ts";

type BuyAKitProps = {
	readonly onCancel: () => void;
};

function BuyAKit({ onCancel }: BuyAKitProps) {
	const t = useTranslator();
	const form = useForm({
		onSubmit(values: FormValues) {
			const locationUrl = values.location as string;
			window.open(locationUrl);
		},
	});
	const { isWorking } = form;

	return (
		<SimplePage onBackClick={onCancel} centerContent>
			<SimpleFormCard
				form={form}
				icon={<BasketIcon />}
				heading={t("Buy a Kit")}
				summary={t(
					"Choose your location to purchase from your local retailer.",
				)}
				submitLabel={t("Next")}
			>
				<SingleSelectField
					name="location"
					options={buyLocationOptions}
					label={t("Choose location")}
					placeholder={t("Select country")}
					disabled={isWorking}
					required
				/>
			</SimpleFormCard>
		</SimplePage>
	);
}

export default BuyAKit;
