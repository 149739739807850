mutation generatePremadeKitInstructions(
  $code: String!
  $name: String!
  $email: String!
  $sourceImageKey: String!
  $numberOfKits: Int!
  $backgroundMaskImageKey: String
  $picture: GeneratepremadekitinstructionsPicture!
) {
  generatePremadeKitInstructions(
    code: $code
    name: $name
    email: $email
    picture: $picture
    numberOfKits: $numberOfKits
    sourceImageKey: $sourceImageKey
    backgroundMaskImageKey: $backgroundMaskImageKey
  )
}
