query tempMediaPresignedUrl($fileName: String!, $contentType: String!) {
  tempMediaPresignedUrl(
    fileName: $fileName
    contentType: $contentType
    acl: "public"
  ) {
    key
    url
  }
}
