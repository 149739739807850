import type { ReactNode } from "preact/compat";
import classes from "./icon-tagged-text.module.css";

type IconTaggedTextProps = {
	readonly icon: ReactNode;
	readonly prefixText?: string;
	readonly text: string;
};

function IconTaggedText({ icon, prefixText, text }: IconTaggedTextProps) {
	return (
		<div className={classes["icon-tagged-text"]}>
			<div className={classes["background"]} />
			<div className={classes["icon"]}>{icon}</div>
			<div className={classes["text-content"]}>
				{prefixText && (
					<div className={classes["prefix-text"]}>{prefixText}</div>
				)}
				<div>{text}</div>
			</div>
		</div>
	);
}

export default IconTaggedText;
