import { clsx } from "clsx";
import classes from "./amount-pill.module.css";

type AmountPillProps = {
	readonly children: number;
	readonly isOdd: boolean;
};

function AmountPill({ isOdd, children }: AmountPillProps) {
	return (
		<div
			className={clsx(
				classes["amount-pill"],
				isOdd ? classes["amount-pill-a"] : classes["amount-pill-b"],
			)}
		>
			{children.toLocaleString()}
		</div>
	);
}

export default AmountPill;
