import type { HTMLAttributes } from "preact/compat";

function ClipboardIcon(
	props: Pick<HTMLAttributes<SVGSVGElement>, "className">,
) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="33"
			height="37"
			viewBox="0 0 33 37"
			fill="none"
			{...props}
		>
			<path
				d="M23.692 5.61523H31.7688V36.3841H1V5.61523H9.07682"
				fill="#F7B806"
			/>
			<path
				d="M23.692 5.61523H31.7688V36.3841H1V5.61523H9.07682"
				stroke="#092F63"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M28.6915 10.231V27.1215L22.5377 33.3076H4.07642V10.231"
				fill="white"
			/>
			<path
				d="M22.5377 30.231H4.07642V33.3078H22.5377V30.231Z"
				fill="#E2E2E2"
			/>
			<path
				d="M19.4612 4.07688V1H13.3074V4.07688H9.46129L7.92285 10.2307H24.8457L23.3073 4.07688H19.4612Z"
				fill="white"
			/>
			<path
				d="M28.6917 10.231V27.1215L22.538 33.3076H4.07666V10.231"
				stroke="#092F63"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M7.92261 10.2309H24.8455L23.307 4.07715H19.4609L7.92261 10.2309Z"
				fill="#E2E2E2"
			/>
			<path
				d="M8.69189 16.3521H24.0763"
				stroke="#092F63"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M8.69189 20.9678H24.0763"
				stroke="#092F63"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M8.69189 25.583H16.3841"
				stroke="#092F63"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M28.6919 27.1216H22.5381V33.2754"
				stroke="#092F63"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M19.4609 4.07688V1H13.3072V4.07688H9.46105L7.92261 10.2307H24.8455L23.307 4.07688H19.4609Z"
				stroke="#092F63"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
}

export default ClipboardIcon;
