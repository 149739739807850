import SliderInput from "~/components/slider-input.tsx";
import { useTranslator } from "~/i18n/context.tsx";
import type { ConfirmSheetProps } from "~/components/sheet/confirm-sheet.tsx";
import ConfirmSheet from "~/components/sheet/confirm-sheet.tsx";
import { usePalettes, usePicture } from "../context.tsx";

function ColoursSheet(
	props: Pick<ConfirmSheetProps, "open" | "onDone" | "onCancel">,
) {
	const t = useTranslator();
	const { picture, patchPictureDebounced } = usePicture();
	const { kitsPalette, kitPlusExtrasPalette } = usePalettes();

	return (
		<ConfirmSheet title={t("Colour Density")} {...props}>
			<form onSubmit={(e) => e.preventDefault()}>
				<SliderInput
					min={kitsPalette.length}
					max={kitPlusExtrasPalette.length}
					step={1}
					value={
						picture.paletteMode.type === "hybrid"
							? picture.paletteMode.palette.length +
								picture.paletteMode.numberOfExtraColours
							: kitsPalette.length
					}
					onChange={(newValue) =>
						patchPictureDebounced({
							paletteMode: {
								type: "hybrid",
								palette: kitsPalette.map((p) => p.brick),
								numberOfExtraColours: newValue - kitsPalette.length,
							},
						})
					}
				/>
			</form>
		</ConfirmSheet>
	);
}

export default ColoursSheet;
