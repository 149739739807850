function SaturationIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
		>
			<path
				d="M19.3481 7.83313C19.3501 8.26456 19.3098 8.69397 19.2305 9.11752C18.205 8.59211 17.0687 8.31985 15.9165 8.32341C14.5279 8.32264 13.1677 8.7171 11.9948 9.46072C10.822 8.7171 9.46178 8.32264 8.07314 8.32341C6.92095 8.31985 5.78469 8.59211 4.75932 9.11752C4.67993 8.69406 4.64057 8.26408 4.64162 7.83313C4.64162 2.17269 10.7693 -1.36511 15.6714 1.46507C17.9466 2.77864 19.3481 5.20609 19.3481 7.83313Z"
				fill="#66E1FF"
			/>
			<path
				d="M11.9958 9.46071C10.2438 10.5577 9.03945 12.3465 8.68195 14.3824C6.61507 13.3472 5.16038 11.3943 4.76025 9.11751C5.78563 8.5921 6.92189 8.31985 8.07408 8.3234C9.46233 8.3234 10.8223 8.71748 11.9958 9.46071Z"
				fill="#78EB7B"
			/>
			<path
				d="M11.9958 21.8827C10.8258 22.6337 9.46437 23.0319 8.0741 23.0298C2.41365 23.022 -1.11588 16.8896 1.72111 11.9913C2.43458 10.7594 3.49039 9.76108 4.76028 9.11749C5.16041 11.3943 6.61509 13.3472 8.68197 14.3824C8.14505 17.3254 9.45842 20.298 11.9958 21.8827Z"
				fill="#FFEF5E"
			/>
			<path
				d="M15.3086 14.3824C13.2294 15.4544 10.7601 15.4544 8.68097 14.3824C9.03857 12.3465 10.2429 10.5578 11.9948 9.46069C13.7467 10.5578 14.9511 12.3465 15.3086 14.3824Z"
				fill="#E3E3E3"
			/>
			<path
				d="M19.2314 9.11751C18.8312 11.3943 17.3766 13.3472 15.3097 14.3824C14.952 12.3466 13.7477 10.5579 11.9958 9.46071C13.1686 8.71709 14.5288 8.32263 15.9176 8.3234C17.0697 8.31985 18.206 8.5921 19.2314 9.11751Z"
				fill="#D480FF"
			/>
			<path
				d="M15.4273 15.6766C15.4328 18.1993 14.1352 20.546 11.9958 21.8827C9.4584 20.2981 8.14503 17.3254 8.68195 14.3824C10.7611 15.4544 13.2304 15.4544 15.3097 14.3824C15.3881 14.809 15.4273 15.2423 15.4273 15.6766Z"
				fill="#C77F67"
			/>
			<path
				d="M23.2697 15.6766C23.2697 19.7378 19.9778 23.03 15.9165 23.0298C14.5263 23.0319 13.1649 22.6337 11.9948 21.8827C14.5323 20.298 15.8457 17.3254 15.3087 14.3824C17.3755 13.3471 18.8301 11.3942 19.2304 9.11749C21.7113 10.3615 23.2753 12.9013 23.2697 15.6766Z"
				fill="#FF808C"
			/>
			<path
				d="M19.3481 7.83313C19.3501 8.26456 19.3098 8.69397 19.2305 9.11752C18.205 8.59211 17.0687 8.31985 15.9165 8.32341C14.5279 8.32264 13.1677 8.7171 11.9948 9.46072C10.822 8.7171 9.46178 8.32264 8.07314 8.32341C6.92095 8.31985 5.78469 8.59211 4.75932 9.11752C4.67993 8.69406 4.64057 8.26408 4.64162 7.83313C4.64162 2.17269 10.7693 -1.36511 15.6714 1.46507C17.9465 2.77864 19.3481 5.20609 19.3481 7.83313Z"
				stroke="#3C3C3C"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M11.9958 9.46071C10.2438 10.5577 9.03945 12.3465 8.68195 14.3824C6.61507 13.3472 5.16038 11.3943 4.76025 9.11751C5.78563 8.5921 6.92189 8.31985 8.07408 8.3234C9.46233 8.3234 10.8223 8.71748 11.9958 9.46071Z"
				stroke="#3C3C3C"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M11.9958 21.8827C10.8258 22.6337 9.46437 23.0319 8.0741 23.0298C2.41365 23.022 -1.11588 16.8896 1.72111 11.9913C2.43458 10.7594 3.49039 9.76108 4.76028 9.11749C5.16041 11.3943 6.61509 13.3472 8.68197 14.3824C8.14505 17.3254 9.45842 20.298 11.9958 21.8827Z"
				stroke="#3C3C3C"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M15.3086 14.3824C13.2294 15.4544 10.7601 15.4544 8.68097 14.3824C9.03857 12.3465 10.2429 10.5578 11.9948 9.46069C13.7467 10.5578 14.9511 12.3465 15.3086 14.3824Z"
				stroke="#3C3C3C"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M15.3097 14.3824C17.3766 13.3472 18.8312 11.3943 19.2314 9.11751C18.206 8.5921 17.0697 8.31985 15.9176 8.3234C14.5288 8.32263 13.1686 8.71709 11.9958 9.46071C13.7477 10.5579 14.952 12.3466 15.3097 14.3824ZM15.3097 14.3824C13.2304 15.4544 10.7611 15.4544 8.68195 14.3824C8.14503 17.3254 9.4584 20.2981 11.9958 21.8827C14.1352 20.546 15.4328 18.1993 15.4273 15.6766C15.4273 15.2423 15.3881 14.809 15.3097 14.3824Z"
				stroke="#3C3C3C"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M23.2697 15.6766C23.2697 19.7378 19.9778 23.03 15.9165 23.0298C14.5263 23.0319 13.1649 22.6337 11.9948 21.8827C14.5323 20.298 15.8457 17.3254 15.3087 14.3824C17.3755 13.3471 18.8301 11.3942 19.2304 9.11749C21.7113 10.3615 23.2753 12.9013 23.2697 15.6766Z"
				stroke="#3C3C3C"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
}

export default SaturationIcon;
