import { useState } from "preact/hooks";
import type { JSX } from "preact/jsx-runtime";
import { unknownToString } from "@dhau/lang-extras";
import { clsx } from "clsx";
import { parseUploadedFile } from "@brickme/project-core/src/browser/image-parsing.ts";
import bitmapToSmallestSizeEncodedImage from "@brickme/project-core/src/browser/bitmap-to-smallest-size-encoded-image.ts";
import { useTranslator } from "~/i18n/context.tsx";
import { useViewer } from "~/viewer/context.tsx";
import type { FormValues } from "~/hooks/use-form.ts";
import useForm from "~/hooks/use-form.ts";
import FormErrors from "~/components/forms/form-errors.tsx";
import { maxResolution } from "~/model.ts";
import UploadImageIcon from "~/components/icons/upload-image.tsx";
import SimplePage from "~/components/simple-page.tsx";
import type { SourceImage } from "../../editor/source-image.ts";
import BestResultsModal from "./best-results-modal.tsx";
import classes from "./upload-image.module.css";

const bestResultsModalId = "best-results-modal";

type PendingImageState = { readonly type: "pending" };
type ParsingImageState = { readonly type: "parsing" };
type ErrorImageState = { readonly type: "error"; readonly message: string };

type UploadImageProps = {
	readonly onComplete: (sourceImage: SourceImage) => void;
	readonly onCancel: () => void;
};

function UploadImage({ onComplete, onCancel }: UploadImageProps) {
	const t = useTranslator();

	const [imageState, setImageState] = useState<
		PendingImageState | ParsingImageState | ErrorImageState
	>({ type: "pending" });
	const onSelectFile = async (file: File) => {
		setImageState({ type: "parsing" });
		try {
			// TODO: Do parsing in a worker since very heavy?
			// Weight of a new worker probably not worth the effort, unless we
			// have some sort of multi-purpose utility worker
			const { bitmap, originalEncodedImage, originalSize } =
				await parseUploadedFile(file, maxResolution);
			// const source = createMaxRequiredResolutionSourceImage(
			// 	inputImage,
			// 	maxNumberOfBaseplates,
			// );
			const smallest = await bitmapToSmallestSizeEncodedImage(
				bitmap,
				// We can only use the original buffer for this purpose if it's the same size, otherwise
				// we run into trouble
				bitmap.width === originalSize.width &&
					bitmap.height === originalSize.height
					? originalEncodedImage
					: undefined,
			);
			onComplete({
				bitmap,
				fileName: file.name,
				contentType: smallest.mimeType,
				content: smallest.buffer,
			});
			setImageState({ type: "pending" });
		} catch (e) {
			setImageState({ type: "error", message: unknownToString(e) });
		}
	};

	// Form submission
	const { formError, formRef, formProps } = useForm({
		onSubmit: (values: FormValues) => {
			const sourceImage = values.sourceImage;
			if (typeof sourceImage === "string") {
				return;
			}

			onSelectFile(sourceImage);
		},
	});
	const { isUsingFacebookBrowser } = useViewer();

	// Drag and drop files
	const [isHighlightedForDrop, setHighlightedForDrop] = useState(false);
	const onDragEnterOrOver = () => {
		setHighlightedForDrop(true);
	};
	const onDragLeave = () => {
		setHighlightedForDrop(false);
	};
	const onDrop = (e: JSX.TargetedDragEvent<HTMLLabelElement>) => {
		e.preventDefault();
		setHighlightedForDrop(false);
		if (!e.dataTransfer) {
			return;
		}

		const { files } = e.dataTransfer;
		if (files.length > 0) {
			onSelectFile(files[0]);
		}
	};

	return (
		<>
			<SimplePage onBackClick={onCancel} centerContent>
				<form {...formProps}>
					<label
						htmlFor="uploadField"
						onDragEnter={onDragEnterOrOver}
						onDragOver={onDragEnterOrOver}
						onDragLeave={onDragLeave}
						onDrop={onDrop}
						className={clsx(
							classes["upload-image-label"],
							isHighlightedForDrop && classes["hover-active"],
						)}
					>
						{t("Upload your image")}
						<UploadImageIcon />
						<input
							id="uploadField"
							type="file"
							name="sourceImage"
							// Issue with accept attr for in-app fb browser
							// https://stackoverflow.com/questions/27000708/
							// file-upload-control-not-working-in-facebook-in-app-browser
							accept={isUsingFacebookBrowser ? undefined : "image/*"}
							required
							onChange={() => {
								formRef.current?.requestSubmit();
							}}
						/>
					</label>
					<FormErrors
						errors={[
							formError,
							imageState.type === "error" ? imageState.message : undefined,
						]}
					/>
				</form>
			</SimplePage>
			<BestResultsModal id={bestResultsModalId} showModalOnMount />
		</>
	);
}

export default UploadImage;
