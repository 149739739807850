function RemoveBgIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
		>
			<path
				d="M11.5 18.75C14.8137 18.75 17.5 16.0637 17.5 12.75C17.5 9.43629 14.8137 6.75 11.5 6.75C8.18629 6.75 5.5 9.43629 5.5 12.75C5.5 16.0637 8.18629 18.75 11.5 18.75Z"
				fill="#66E1FF"
			/>
			<path
				d="M19.5 8.25C19.456 8.58875 19.4903 8.93308 19.6004 9.25646C19.7104 9.57984 19.8933 9.87363 20.1348 10.1152C20.3764 10.3567 20.6702 10.5396 20.9935 10.6496C21.3169 10.7597 21.6613 10.794 22 10.75C20.385 10.744 19.582 11.61 19.5 13.25C19.5482 12.9105 19.5168 12.5643 19.4082 12.239C19.2997 11.9137 19.1169 11.6181 18.8744 11.3756C18.6319 11.1331 18.3363 10.9503 18.011 10.8418C17.6857 10.7332 17.3395 10.7018 17 10.75C17.3387 10.7938 17.683 10.7594 18.0062 10.6492C18.3295 10.5391 18.6232 10.3562 18.8647 10.1147C19.1062 9.87322 19.2891 9.57952 19.3992 9.25624C19.5094 8.93295 19.5438 8.58871 19.5 8.25Z"
				fill="#F7B907"
			/>
			<path
				d="M12.5 2.25C12.489 5.077 13.868 6.683 17 6.75C14.093 6.739 12.648 8.299 12.5 11.25C12.475 8.466 11.227 6.75 8 6.75C10.888 6.71 12.489 5.316 12.5 2.25Z"
				fill="#F7B907"
			/>
			<path
				d="M0.5 18.75V20.75C0.5 21.0152 0.605357 21.2696 0.792893 21.4571C0.98043 21.6446 1.23478 21.75 1.5 21.75H3.5"
				stroke="#3C3C3C"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M3.5 2.75H1.5C1.23478 2.75 0.98043 2.85536 0.792893 3.04289C0.605357 3.23043 0.5 3.48478 0.5 3.75V5.75"
				stroke="#3C3C3C"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M23.5 5.75V3.75C23.5 3.48478 23.3946 3.23043 23.2071 3.04289C23.0196 2.85536 22.7652 2.75 22.5 2.75H20.5"
				stroke="#3C3C3C"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M20.5 21.75H22.5C22.7652 21.75 23.0196 21.6446 23.2071 21.4571C23.3946 21.2696 23.5 21.0152 23.5 20.75V18.75"
				stroke="#3C3C3C"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M17.158 14.75C16.8628 15.5837 16.3869 16.3419 15.7644 16.9701C15.1419 17.5984 14.3882 18.0813 13.5573 18.3843C12.7263 18.6872 11.8387 18.8027 10.9579 18.7225C10.0771 18.6423 9.22493 18.3683 8.4624 17.9202C7.69986 17.4721 7.04581 16.861 6.54708 16.1306C6.04834 15.4002 5.71725 14.5686 5.57751 13.6952C5.43776 12.8219 5.49282 11.9285 5.73874 11.0789C5.98466 10.2293 6.41536 9.44465 7 8.78101"
				stroke="#3C3C3C"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M19.5 8.25C19.456 8.58875 19.4903 8.93308 19.6004 9.25646C19.7104 9.57984 19.8933 9.87363 20.1348 10.1152C20.3764 10.3567 20.6702 10.5396 20.9935 10.6496C21.3169 10.7597 21.6613 10.794 22 10.75C20.385 10.744 19.582 11.61 19.5 13.25C19.5482 12.9105 19.5168 12.5643 19.4082 12.239C19.2997 11.9137 19.1169 11.6181 18.8744 11.3756C18.6319 11.1331 18.3363 10.9503 18.011 10.8418C17.6857 10.7332 17.3395 10.7018 17 10.75C17.3387 10.7938 17.683 10.7594 18.0062 10.6492C18.3295 10.5391 18.6232 10.3562 18.8647 10.1147C19.1062 9.87322 19.2891 9.57952 19.3992 9.25624C19.5094 8.93295 19.5438 8.58871 19.5 8.25Z"
				stroke="#3C3C3C"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M12.5 2.25C12.489 5.077 13.868 6.683 17 6.75C14.093 6.739 12.648 8.299 12.5 11.25C12.475 8.466 11.227 6.75 8 6.75C10.888 6.71 12.489 5.316 12.5 2.25Z"
				stroke="#3C3C3C"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
}

export default RemoveBgIcon;
