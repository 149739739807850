import { ensureExhaustive } from "@dhau/lang-extras";
import type { SourceImage } from "../editor/source-image.ts";
import type { SourceRotation } from "./config.ts";
import { rotateBitmapAtRightAngles } from "@brickme/project-core/src/index.ts";
import bitmapToSmallestSizeEncodedImage from "@brickme/project-core/src/browser/bitmap-to-smallest-size-encoded-image.ts";

async function rotateSourceImage(
	sourceImage: SourceImage,
	rotation: SourceRotation,
): Promise<SourceImage> {
	switch (rotation) {
		case 0:
			return sourceImage;
		case 90:
		case 180:
		case 270:
			{
				const { bitmap, fileName } = sourceImage;
				const rotatedBitmap = rotateBitmapAtRightAngles(bitmap, rotation);
				const encoded = await bitmapToSmallestSizeEncodedImage(
					rotatedBitmap,
					undefined,
				);
				return {
					bitmap: rotatedBitmap,
					fileName,
					contentType: encoded.mimeType,
					content: encoded.buffer,
				};
			}
			break;
		default:
			ensureExhaustive(rotation);
	}
}

export default rotateSourceImage;
