import { useState, useEffect } from "preact/hooks";
import { memo } from "preact/compat";
import { range } from "lodash-es";

const shadowSize = 2;

type DimensionsImageProps = {
	readonly width: number;
	readonly height: number;
};

function DimensionsImage({ width, height }: DimensionsImageProps) {
	// const cols = range(0, width);
	const [canvasRef, setCanvasRef] = useState<HTMLCanvasElement | null>(null);
	useEffect(() => {
		if (!canvasRef) {
			return;
		}
		const c = canvasRef.getContext("2d");
		if (!c) {
			throw new Error("No context 2d");
		}

		c.clearRect(0, 0, canvasRef.width, canvasRef.height);
		const border = shadowSize;
		const canvasWidth = canvasRef.width - 2 * border;
		const canvasHeight = canvasRef.height - 2 * border;

		const margin = 0; // Math.max(1, 6 - Math.max(width, height));
		const widthCellSize = (canvasWidth - margin * (width - 1)) / width;
		const heightCellSize = (canvasHeight - margin * (height - 1)) / height;
		const cellSize = Math.min(widthCellSize, heightCellSize);
		const xStart =
			border + (canvasWidth - ((cellSize + margin) * width - margin)) / 2;
		const yStart =
			border + (canvasHeight - ((cellSize + margin) * height - margin)) / 2;

		c.lineWidth = Math.sqrt(margin);
		c.strokeStyle = "black";
		c.fillStyle = "black";
		range(0, width).forEach((x) => {
			range(0, height).forEach((y) => {
				const cellX = xStart + x * (cellSize + margin);
				const cellY = yStart + y * (cellSize + margin);
				c.strokeRect(cellX, cellY, cellSize, cellSize);
				// Top shadow
				if (y === 0) {
					c.moveTo(cellX, cellY);
					c.beginPath();
					c.lineTo(cellX + shadowSize, cellY - shadowSize);
					c.lineTo(cellX + cellSize + shadowSize, cellY - shadowSize);
					c.lineTo(cellX + cellSize, cellY);
					c.lineTo(cellX, cellY);
					c.fill();
				}
				// Right shadow
				if (x >= width - 1) {
					c.moveTo(cellX + cellSize, cellY);
					c.beginPath();
					c.lineTo(cellX + cellSize + shadowSize, cellY - shadowSize);
					c.lineTo(
						cellX + cellSize + shadowSize,
						cellY + cellSize - shadowSize,
					);
					c.lineTo(cellX + cellSize, cellY + cellSize);
					c.lineTo(cellX + cellSize, cellY);
					c.fill();
				}
			});
		});
	}, [canvasRef, width, height]);
	return <canvas ref={setCanvasRef} width={48} height={48} />;
}

export default memo(DimensionsImage);
