import { useCallback, useRef } from "preact/hooks";
import { Swiper } from "swiper";
import { Pagination } from "swiper/modules";
import { clsx } from "clsx";
import { useTranslator } from "~/i18n/context.tsx";
import ChevronRightIcon from "~/components/icons/chevron-right.tsx";
import BlueBrickIcon from "~/components/icons/blue-brick.tsx";
import ClipboardIcon from "~/components/icons/clipboard.tsx";
import BasketIcon from "~/components/icons/basket.tsx";
import slide1Image from "./slide-1.png?w=272&h=361&format=webp&as=metadata";
import slide2Image from "./slide-2.png?w=272&h=361&format=webp&as=metadata";
import slide3Image from "./slide-3.png?w=272&h=361&format=webp&as=metadata";
import "swiper/css";
import "swiper/css/pagination";
import classes from "./intro.module.css";

const swiperImages = [
	{ ...slide1Image, alt: "Dog mosaic" },
	{ ...slide2Image, alt: "Car mosaic" },
	{ ...slide3Image, alt: "Bridge mosaic" },
];

type IntroProps = {
	readonly onStartTryItOut: () => void;
	readonly onStart: () => void;
	readonly onBuy: () => void;
};

function Intro({ onStartTryItOut, onStart, onBuy }: IntroProps) {
	const t = useTranslator();
	const swiperRef = useRef<Swiper | undefined>(undefined);
	const setSwiperRef = useCallback(
		(newRef: HTMLDivElement | null) => {
			const { current: previousSwiper } = swiperRef;
			if (previousSwiper) {
				previousSwiper.destroy();
				swiperRef.current = undefined;
			}

			if (newRef === null) {
				return;
			}

			// eslint-disable-next-line no-param-reassign
			newRef.style.visibility = "visible";
			const swiper = new Swiper(newRef, {
				modules: [Pagination],
				initialSlide: 1,
				slidesPerView: 1,
				breakpoints: {
					800: {
						slidesPerView: 3,
					},
				},
				// spaceBetween: 20,
				pagination: {
					el: ".swiper-pagination",
					bulletActiveClass: clsx(
						"swiper-pagination-bullet-active",
						classes["swiper-pagination-bullet-active"],
					),
					bulletClass: clsx(
						"swiper-pagination-bullet",
						classes["swiper-pagination-bullet"],
					),
				},
				centeredSlides: true,
			});
			function renderSlides() {
				swiper.slides.forEach((slide, i) => {
					const indexDiff = i - swiper.activeIndex;
					const indexDiffSign =
						indexDiff === 0 ? 0 : indexDiff / Math.abs(indexDiff);
					const rotation = indexDiffSign * indexDiff * indexDiff * 6;
					const yOffset =
						Math.pow(Math.abs(rotation), 1.9) * 0.2 + Math.abs(rotation) * 1.2;

					// eslint-disable-next-line no-param-reassign
					slide.style.transform = `rotate(${rotation}deg) translateY(${yOffset}px)`;

					swiper.update();
				});
			}
			swiper.on("activeIndexChange", renderSlides);
			renderSlides();

			swiperRef.current = swiper;
		},
		[swiperRef],
	);

	return (
		<div className={classes["intro-container"]}>
			<div className={classes["intro-bg"]} />
			<div className={classes["intro-content"]}>
				<h1 className="heading-1">Welcome to Pix-a-Pic Mosaic Kit Creator</h1>
				<div ref={setSwiperRef} class={clsx("swiper", classes["swiper"])}>
					<div class="swiper-wrapper">
						{swiperImages.map((swiperImage) => (
							<div
								key={swiperImage.src}
								class={clsx("swiper-slide", classes["swiper-slide"])}
							>
								<img
									src={swiperImage.src}
									width={swiperImage.width}
									height={swiperImage.height}
									alt={swiperImage.alt}
								/>
							</div>
						))}
					</div>
					<div class="swiper-pagination"></div>
				</div>
				<div className={classes["intro-options"]}>
					<div>Choose an option below to get started.</div>
					<ul className={classes["intro-option-buttons"]}>
						<li>
							<button type="button" onClick={onStartTryItOut}>
								<div className={classes["intro-option-button-icon"]}>
									<BlueBrickIcon />
								</div>
								<div className={classes["intro-option-button-main"]}>
									<div className={classes["intro-option-button-top-text"]}>
										{t("Don’t have kit yet?")}
									</div>
									<div className={classes["intro-option-button-bottom-text"]}>
										{t("Try it out")}
									</div>
								</div>
								<ChevronRightIcon />
							</button>
						</li>
						<li>
							<button type="button" onClick={onStart}>
								<div className={classes["intro-option-button-icon"]}>
									<ClipboardIcon />
								</div>
								<div className={classes["intro-option-button-main"]}>
									<div className={classes["intro-option-button-top-text"]}>
										{t("I have a kit!")}
									</div>
									<div className={classes["intro-option-button-bottom-text"]}>
										{t("Get your Instructions")}
									</div>
								</div>
								<ChevronRightIcon />
							</button>
						</li>
						<li>
							<button type="button" onClick={onBuy}>
								<div className={classes["intro-option-button-icon"]}>
									<BasketIcon />
								</div>
								<div className={classes["intro-option-button-main"]}>
									<div className={classes["intro-option-button-top-text"]}>
										{t("Need a kit?")}
									</div>
									<div className={classes["intro-option-button-bottom-text"]}>
										{t("Buy a kit here")}
									</div>
								</div>
								<ChevronRightIcon />
							</button>
						</li>
					</ul>
				</div>
			</div>
		</div>
	);
}

export default Intro;
