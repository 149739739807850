import { render } from "preact";
import { StrictMode } from "preact/compat";
import { init as sentryInit, browserTracingIntegration } from "@sentry/react";
import { optionalRuntimeEnv, requiredEnv } from "./utils/ensure-env.ts";
import App from "./app/app.tsx";
import "invokers-polyfill";
import packageInfo from "../package.json";
import "./styles/global.css";

// eslint-disable-next-line no-console
console.log("App Version", packageInfo.version);

const sentryDsn = optionalRuntimeEnv("SENTRY_DSN");
if (sentryDsn) {
	const sentryEnvironment = optionalRuntimeEnv("SENTRY_ENVIRONMENT");
	const sentryRelease = optionalRuntimeEnv("SENTRY_RELEASE");
	sentryInit({
		dsn: sentryDsn,
		environment: sentryEnvironment,
		release: sentryRelease,
		integrations: [browserTracingIntegration()],
		normalizeDepth: 6,
		// TODO: Check on performance impacts?
		// Samples for every session in general. Don't want samples for non-error
		// replaysSessionSampleRate: 0,
		// // We want every error session sampled
		// replaysOnErrorSampleRate: 1.0,
	});
}

// Fix vh for mobile browsers. See https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
function updateViewHeight() {
	const vh = window.innerHeight * 0.01;
	document.documentElement.style.setProperty("--vh", `${vh}px`);
}
updateViewHeight();
window.addEventListener("resize", updateViewHeight);

render(
	<StrictMode>
		<App
			userAgent={navigator.userAgent || navigator.vendor || "unknown"}
			// TODO: Should have a dedicated api url for premade kits
			apiUrl={requiredEnv("VITE_API_URL")}
			shopifyConfig={{
				shopDomain: requiredEnv("VITE_SHOPIFY_SHOP_DOMAIN"),
				storefrontAccessToken: requiredEnv("VITE_STOREFRONT_ACCESS_TOKEN"),
			}}
		/>
	</StrictMode>,
	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	document.getElementById("root")!,
);

// console.error("TODO: Remove this testing code");
// const myUnusedVar = "prevents accidental commit"
// async function waitForReactRender() {
// 	return new Promise((resolve) => setTimeout(resolve, 0));
// }
// async function waitForElement<T extends HTMLElement>(selector: string, predicate?: <T extends HTMLElement>(element: T) => boolean) {
// 	const start = new Date();
// 	return new Promise<T>((resolve) => {
// 		const interval = setInterval(() => {
// 			const end = new Date();
// 			if ((end.getTime() - start.getTime()) > 5_000) {
// 				throw new Error(`Timeout for ${selector}`)
// 			}

// 			const elements = document.querySelectorAll<T>(selector);

// 			// Keep waiting
// 			if (elements.length === 0) {
// 				return;
// 			}

// 			const predicateMatches = Array.from(elements).filter(e => !predicate || predicate(e));

// 			if (predicateMatches.length > 1) {
// 				throw new Error("Multiple matches found for " + selector);
// 			}

// 			if (predicateMatches.length === 1) {
// 				clearInterval(interval);
// 				resolve(predicateMatches[0]);
// 				return;
// 			}
// 		}, 100);
// 	});
// }

// (async () => {
// 	await waitForReactRender();
// 	const buttons = document.evaluate(
// 		"//button[contains(., 'I have a kit')]",
// 		document,
// 		null,
// 		XPathResult.ANY_TYPE,
// 		null,
// 	);
// 	const button = buttons.iterateNext();
// 	button?.dispatchEvent(new MouseEvent("click", { bubbles: true }));

// 	const input = await waitForElement<HTMLInputElement>('input[type="text"]');
// 	input.value = "TEST01";
// 	const submit = await waitForElement<HTMLButtonElement>(
// 		'button[type="submit"]',
// 	);
// 	submit.dispatchEvent(new MouseEvent("click", { bubbles: true }));

// 	const oneKitRadio = await waitForElement<HTMLInputElement>('input[type="radio"][value="2"]');
// 	oneKitRadio.setAttribute("checked", "checked");
// 	const submitKits = await waitForElement<HTMLButtonElement>(
// 		'button[type="submit"]',
// 	);
// 	submitKits.dispatchEvent(new MouseEvent("click", { bubbles: true }));

// 	const { default: image } = await import("./features/editor/complete/colourised-image.png");
// 	const response = await fetch(image);
// 	const blob = await response.blob();
// 	const uploadLabel = (await waitForElement('input[type="file"]')).closest("label")!;
// 	const event = new Event("drop");
// 	(event as any).dataTransfer = {
// 		files: [new File([blob], "tiger.png", { type: "image/png" })],
// 	};
// 	uploadLabel.dispatchEvent(event);
// 	const confirmPhoto = await waitForElement<HTMLButtonElement>(
// 		'button[type="button"]',
// 		(element) => element.innerHTML.includes("Next")
// 	);
// 	confirmPhoto.dispatchEvent(new MouseEvent("click", { bubbles: true }));

// 	// Select a layout
// 	const layout = await waitForElement<HTMLButtonElement>(
// 		'button[aria-label="2x2"]',
// 	);
// 	layout.dispatchEvent(new MouseEvent("click", { bubbles: true }));

// 	const finishButton = await waitForElement<HTMLButtonElement>(
// 		'button[aria-label="Finish"]',
// 	);
// 	finishButton.dispatchEvent(new MouseEvent("click", { bubbles: true }));

// 	// Add colours
// 	const addColoursButton = await waitForElement<HTMLButtonElement>(
// 		'button[aria-label="Add colours?"]',
// 	);
// 	addColoursButton.dispatchEvent(new MouseEvent("click", { bubbles: true }));
// 	const letsAddColoursButton = await waitForElement<HTMLButtonElement>(
// 		'button[aria-label="Let\'s add colours"]',
// 	);
// 	letsAddColoursButton.dispatchEvent(new MouseEvent("click", { bubbles: true }));
// })().catch(e => console.error("Error testing code process", e));
