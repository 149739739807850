function MoveIcon() {
	return (
		<svg
			width="52"
			height="52"
			viewBox="0 0 52 52"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M21.6667 19.5H30.3333V13H36.8333L26 2.16663L15.1667 13H21.6667V19.5ZM19.5 21.6666H13V15.1666L2.16666 26L13 36.8333V30.3333H19.5V21.6666ZM49.8333 26L39 15.1666V21.6666H32.5V30.3333H39V36.8333L49.8333 26ZM30.3333 32.5H21.6667V39H15.1667L26 49.8333L36.8333 39H30.3333V32.5Z"
				fill="white"
			/>
		</svg>
	);
}

export default MoveIcon;
