import { sortBy } from "lodash-es";
import type { GenericSize } from "@brickme/project-core/src";
import { premadeKit } from "@brickme/project-core/src";

const allLayouts = [
	// Single kit
	{ width: 1, height: 4 },
	{ width: 1, height: 3 },
	{ width: 2, height: 2 },
	{ width: 3, height: 1 },
	{ width: 4, height: 1 },

	// Double kit
	{ width: 2, height: 4 },
	{ width: 4, height: 2 },

	// Triple kit
	{ width: 3, height: 3 },
	{ width: 3, height: 4 },
	{ width: 4, height: 3 },

	// Quadruple kit
	{ width: 2, height: 6 },
	{ width: 3, height: 5 },
	{ width: 4, height: 4 },
	{ width: 5, height: 3 },
	{ width: 6, height: 2 },
];

const maxLayoutDimension = Math.max(
	...allLayouts.flatMap(({ width, height }) => [width, height]),
);

const maxConsideredNumberOfKits = 4;

function isLayoutCandidateForDefault({ width, height }: GenericSize<number>) {
	return width === height;
}

function getLayoutPermutations(numberOfKits: number) {
	if (numberOfKits < 1) {
		throw new Error("number of kits must be >= 0");
	}

	const calcNumberOfKits = Math.min(numberOfKits, maxConsideredNumberOfKits);
	const previousNumBaseplates =
		(calcNumberOfKits - 1) * premadeKit.numberOfBasePlates;
	const numBaseplates = calcNumberOfKits * premadeKit.numberOfBasePlates;
	const layoutsExactlyForThisNumberOfKits = allLayouts.filter(
		({ width, height }) => {
			// Eyal doesn't want single kit options to display if choose 2 kits
			const area = width * height;
			return area > previousNumBaseplates && area <= numBaseplates;
		},
	);

	let allAvailableLayouts = layoutsExactlyForThisNumberOfKits;
	// No default layout? Add one from previous number of kits, if that exists
	if (
		!layoutsExactlyForThisNumberOfKits.some(isLayoutCandidateForDefault) &&
		calcNumberOfKits > 1
	) {
		allAvailableLayouts = [
			...getLayoutPermutations(calcNumberOfKits - 1).filter(
				isLayoutCandidateForDefault,
			),
			...allAvailableLayouts,
		];
	}

	if (allAvailableLayouts.length === 0) {
		throw new Error(
			`No available layouts for previous ${previousNumBaseplates} and current ${numBaseplates} baseplates`,
		);
	}
	return sortBy(allAvailableLayouts, [
		({ width, height }) => width * height,
		({ width }) => width,
	]);
}

function getDefaultLayout(numberOfKits: number) {
	const squarePermutations = getLayoutPermutations(numberOfKits).filter(
		isLayoutCandidateForDefault,
	);
	if (squarePermutations.length === 0) {
		throw new Error(
			`Found no square layout permutations for ${numberOfKits} kits`,
		);
	}
	return squarePermutations[Math.floor((squarePermutations.length - 1) / 2)];
}

export { maxLayoutDimension, getDefaultLayout, getLayoutPermutations };
