import type { HTMLAttributes } from "preact/compat";

type SelectedIconProps = Pick<HTMLAttributes<SVGSVGElement>, "className">;

function SelectedIcon({ className }: SelectedIconProps) {
	return (
		<svg
			className={className}
			width="31"
			height="31"
			viewBox="0 0 31 31"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle
				cx="15.54"
				cy="15.4668"
				r="14.7118"
				fill="#E83044"
				stroke="white"
			/>
			<path
				d="M25.0666 8.92547C25.4763 9.42302 25.4763 9.92057 25.0666 10.4181L13.4766 22.0081C12.979 22.4178 12.4815 22.4178 11.984 22.0081L6.01336 16.0375C5.60362 15.5399 5.60362 15.0424 6.01336 14.5449C6.51091 14.1351 7.00846 14.1351 7.50601 14.5449L12.7303 19.7691L23.5739 8.92547C24.0715 8.51573 24.569 8.51573 25.0666 8.92547Z"
				fill="white"
			/>
		</svg>
	);
}

export default SelectedIcon;
