import type { GenericSize, Bitmap } from "@brickme/project-core/src";
import type { RenderSource, RenderRunner } from "./render-runner-type.ts";
import type { RenderWorkerIncomingData } from "./render-worker-data.ts";
// import workerConstructor from "./render-worker.ts?worker";

// Interesting observation, main thread rendering was faster in one test i did. 2.5ms
// compared to 7-14. Pretty small example though. I'm keeping in worker for better ui
// responsiveness. Test again when have a larger project

function createRenderWorkerBridge(): RenderRunner<HTMLCanvasElement> {
	// const { default: workerConstructor } = await import(
	// 	"./render-worker.ts?worker"
	// );
	// console.log("workerConstructor", workerConstructor);
	// const renderWorker = new workerConstructor();
	// Note: neither format of worker works locally for me atm. Either in a built preview or
	// in dev mode. Haven't tried an isolated hello world. It's working fine on dev though.
	const renderWorker = new Worker(
		new URL("./render-worker.ts", import.meta.url),
		{
			type: "module",
		},
	);

	return {
		setSourceImage(sourceImage: Bitmap, canvas: HTMLCanvasElement) {
			const offscreenCanvas = canvas.transferControlToOffscreen();
			renderWorker.postMessage(
				{
					type: "set-source-image",
					canvas: offscreenCanvas,
					sourceImage,
				} as RenderWorkerIncomingData,
				[offscreenCanvas],
			);
		},
		setCanvas(canvas: HTMLCanvasElement) {
			const offscreenCanvas = canvas.transferControlToOffscreen();
			renderWorker.postMessage(
				{
					type: "set-canvas",
					canvas: offscreenCanvas,
				} as RenderWorkerIncomingData,
				[offscreenCanvas],
			);
		},
		setSource(
			newSource: RenderSource | undefined,
			newUtilityCanvas: HTMLCanvasElement,
			canvasSize: GenericSize<number>,
		) {
			const utilityCanvas = newUtilityCanvas.transferControlToOffscreen();
			renderWorker.postMessage(
				{
					type: "set-source",
					source: newSource,
					utilityCanvas,
					canvasSize,
				} as RenderWorkerIncomingData,
				[utilityCanvas],
			);
		},
		dispose() {
			renderWorker.terminate();
		},
	};
}

export { createRenderWorkerBridge };
