function ChevronUpIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="11.79"
			height="6.63"
			viewBox="0 0 11.79 6.63"
		>
			<polygon points="11.79 4.67 10.24 6.63 5.89 3.19 1.55 6.63 0 4.67 5.89 0 11.79 4.67" />
		</svg>
	);
}

export default ChevronUpIcon;
