function MobileBarsIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="48"
			height="48"
			viewBox="0 0 48 45"
			fill="none"
			stroke="currentColor"
		>
			<path d="M11 11H37" strokeWidth="3" strokeLinecap="round"></path>
			<path d="M11 22H37" strokeWidth="3" strokeLinecap="round"></path>
			<path d="M11 33H37" strokeWidth="3" strokeLinecap="round"></path>
		</svg>
	);
}

export default MobileBarsIcon;
