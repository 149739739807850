import { clsx } from "clsx";
import type { ReactNode } from "preact/compat";
import BottomSheet from "~/components/sheet/bottom-sheet.tsx";
import classes from "./bottom-menu.module.css";

type ButtonDef = {
	readonly id: string;
	readonly label: string;
	readonly icon: ReactNode;
	readonly onClick: () => void;
};

type BottomMenuProps = {
	readonly buttons: readonly ButtonDef[];
	readonly activeId?: string;
};

function BottomMenu({ activeId, buttons }: BottomMenuProps) {
	return (
		<BottomSheet open>
			<ul className={classes["button-list"]}>
				{buttons.map((b) => (
					<li key={b.label}>
						<button
							type="button"
							onClick={b.onClick}
							aria-label={b.label}
							className={clsx(
								classes["bottom-menu-button"],
								b.id === activeId && classes["bottom-menu-button-active"],
							)}
						>
							<div className={classes["button-icon"]}>{b.icon}</div>
							{b.label}
						</button>
					</li>
				))}
			</ul>
		</BottomSheet>
	);
}

export default BottomMenu;
