import FormErrors from "~/components/forms/form-errors.tsx";
import SingleSelectField from "~/components/forms/single-select-field.tsx";
import type { ModalProps } from "~/components/modal.tsx";
import Modal from "~/components/modal.tsx";
import buyLocationOptions from "~/features/buy-a-kit/buy-location-options.ts";
import useForm, { type FormValues } from "~/hooks/use-form.ts";
import { useTranslator } from "~/i18n/context.tsx";

const buyAKitFormId = "buy-a-kit-form";

type BuyAKitModalProps = Pick<ModalProps, "id">;

function BuyAKitModal({ id }: BuyAKitModalProps) {
	const t = useTranslator();

	const { isWorking, formProps, formError } = useForm({
		id: buyAKitFormId,
		onSubmit(values: FormValues) {
			const locationUrl = values.location as string;
			window.open(locationUrl);
		},
	});

	return (
		<Modal
			id={id}
			size="small"
			cancelActionButton
			actionButtons={[
				{
					key: "buy",
					type: "submit",
					form: buyAKitFormId,
					children: t("Buy a kit"),
					variant: "blue",
				},
			]}
		>
			<h2 className="heading-2">{t("Buy a kit")}</h2>
			<form {...formProps}>
				<SingleSelectField
					name="location"
					options={buyLocationOptions}
					label={t("Choose location")}
					placeholder={t("Select country")}
					disabled={isWorking}
					required
				/>
				<FormErrors errors={[formError]} />
			</form>
		</Modal>
	);
}

export default BuyAKitModal;
