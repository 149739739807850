function ChevronRightIcon() {
	return (
		<svg
			width="16"
			height="16"
			viewBox="0 0 16 16"
			xmlns="http://www.w3.org/2000/svg"
			fill="currentColor"
		>
			<path d="M12.0538 7.38999C12.3886 7.79657 12.3886 8.20314 12.0538 8.60972L5.16592 15.4976C4.75934 15.8324 4.35277 15.8324 3.94619 15.4976C3.61136 15.091 3.61136 14.6845 3.94619 14.2779L10.2242 7.99986L3.94619 1.72183C3.61136 1.31525 3.61136 0.908675 3.94619 0.502098C4.35277 0.16727 4.75934 0.16727 5.16592 0.502098L12.0538 7.38999Z" />
		</svg>
	);
}

export default ChevronRightIcon;
