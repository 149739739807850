import { clsx } from "clsx";
import type { ReactNode } from "preact/compat";
import classes from "./form-row.module.css";

type FormRowProps = {
	readonly className?: string;
	readonly children: ReactNode;
};

function FormRow({ className, children }: FormRowProps) {
	return <div className={clsx(classes["form-row"], className)}>{children}</div>;
}

export default FormRow;
