import { useState, type ReactNode } from "preact/compat";
import { clsx } from "clsx";
import { pick } from "lodash-es";
import Button from "~/components/button.tsx";
import Logo from "~/components/logo.tsx";
import FaqsModal from "~/features/content/faqs-modal.tsx";
import { useTranslationControls, useTranslator } from "~/i18n/context.tsx";
import QuestionMarkIcon from "~/components/icons/question-mark.tsx";
import MobileBarsIcon from "~/components/icons/mobile-bars.tsx";
import appLogoLargeImage from "./app-logo.png?w=169&h=68&format=webp&as=metadata";
import appLogoSmallImage from "./app-logo.png?w=119&h=48&format=webp&as=metadata";
import classes from "./layout.module.css";

const faqsModalId = "faqs-modal";

type LayoutProps = {
	readonly children: ReactNode;
};

function Layout({ children }: LayoutProps) {
	const { languageOptions, currentLanguage, setCurrentLanguage } =
		useTranslationControls();
	const t = useTranslator();
	const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
	return (
		<>
			<div className={classes.layout}>
				<header>
					<div className={classes["header-logo"]}>
						<Logo />
					</div>
					<picture>
						<source
							srcSet={appLogoSmallImage.src}
							width={appLogoSmallImage.width}
							height={appLogoSmallImage.height}
							media="(max-width: 767px)"
						/>
						<img
							{...pick(appLogoLargeImage, "src", "width", "height")}
							alt={t("Pix a pic mosaic kit")}
						/>
					</picture>

					<div className={classes["header-mobile-button"]}>
						<button
							type="button"
							onClick={() => setMobileMenuOpen((p) => !p)}
							className={classes["mobile-menu-button"]}
						>
							<MobileBarsIcon />
						</button>
					</div>
					<div
						className={clsx(
							classes["header-actions"],
							mobileMenuOpen && classes["header-actions-mobile-open"],
						)}
					>
						<div className={classes["language-control"]}>
							<div className={classes.select}>
								<select
									value={currentLanguage.code}
									onChange={(e) => {
										const selectedLanguage = languageOptions.find(
											(o) => o.code === e.currentTarget.value,
										);
										if (!selectedLanguage) {
											// Invalid language option
											return;
										}
										setCurrentLanguage(selectedLanguage);
									}}
								>
									{languageOptions.map((o) => (
										<option key={o} value={o.code}>
											{o.code.toUpperCase()}
										</option>
									))}
								</select>
								<span className={classes["select-focus"]}></span>
							</div>
						</div>
						{/* Client asked for reviews to be removed for now */}
						{/* <Button
							type="button"
							variant="bubble"
							commandfor={reviewsModalId}
							command="show-modal"
						>
							<ChatIcon />
						</Button> */}
						<Button
							type="button"
							variant="bubble"
							commandfor={faqsModalId}
							command="show-modal"
						>
							<QuestionMarkIcon />
						</Button>
					</div>
				</header>
				<main className={classes.main}>{children}</main>
			</div>
			<FaqsModal id={faqsModalId} />
			{/* Client asked for reviews to be removed for now */}
			{/* <ReviewsModal id={reviewsModalId} /> */}
		</>
	);
}

export default Layout;
