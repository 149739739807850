import { useEffect } from "preact/hooks";
import {
	corePictureToTransportPicture,
	numberOfBrickColours,
} from "@brickme/project-core/src";
import FormErrors from "~/components/forms/form-errors.tsx";
import type { ModalProps } from "~/components/modal.tsx";
import Modal from "~/components/modal.tsx";
import ClipboardIcon from "~/components/icons/clipboard.tsx";
import type { FormValues } from "~/hooks/use-form.ts";
import useForm from "~/hooks/use-form.ts";
import TextField from "~/components/forms/text-field.tsx";
import FormRow from "~/components/forms/form-row.tsx";
import { useTranslator } from "~/i18n/context.tsx";
import { useApiClient } from "~/api/context.tsx";
import SummaryText from "~/components/summary-text.tsx";
import Button from "~/components/button.tsx";
import {
	useBuildSourcesMediaKeys,
	useCurrentBrickedBuild,
	usePicture,
} from "../context.tsx";
import type {
	GeneratePremadeKitInstructionsMutation,
	GeneratePremadeKitInstructionsMutationVariables,
} from "./generate-premade-kit-instructions-mutation.generated.ts";
import generatePremadeKitInstructionsMutation from "./generate-premade-kit-instructions-mutation.graphql";
import classes from "./get-instructions-modal.module.css";

const formId = "get-instructions-form";

type GetInstructionsModalProps = Pick<ModalProps, "id"> & {
	readonly code: string;
	readonly numberOfKits: number;
};

function GetInstructionsModal({
	id,
	code,
	numberOfKits,
}: GetInstructionsModalProps) {
	const t = useTranslator();
	const { picture } = usePicture();
	const bricked = useCurrentBrickedBuild();
	const { sourceImageKey, backgroundMaskImageKey } = useBuildSourcesMediaKeys();
	const apiClient = useApiClient();
	const {
		formProps,
		isWorking,
		formError,
		successValues,
		formRef,
		resetFormState,
	} = useForm({
		id: formId,
		onSubmit: async (values: FormValues) => {
			if (!bricked) {
				throw new Error("Project not built");
			}
			if (!sourceImageKey) {
				throw new Error("Waiting for image to upload");
			}

			const { brickCounts } = bricked;
			const email = values.email as string;
			const name = values.name as string;
			await apiClient.mutate<
				GeneratePremadeKitInstructionsMutation,
				GeneratePremadeKitInstructionsMutationVariables
			>(generatePremadeKitInstructionsMutation, {
				name,
				email,
				code,
				numberOfKits,
				sourceImageKey,
				backgroundMaskImageKey,
				picture: corePictureToTransportPicture(
					picture,
					numberOfBrickColours(brickCounts),
				),
			});
			formRef.current?.reset();
		},
	});
	useEffect(() => {
		if (!open) {
			resetFormState();
		}
	}, [resetFormState, open]);

	return (
		<Modal id={id} size="small" working={isWorking}>
			<form {...formProps} className={classes["form"]}>
				<ClipboardIcon className={classes["icon"]} />
				<h2>{t("Get instructions")}</h2>
				<SummaryText>
					{t(
						`We'll send the instructions to your email. Please add your name to improve deliverability.`,
					)}
				</SummaryText>
				<FormRow>
					<TextField
						type="email"
						name="email"
						label={t("Email")}
						placeholder={t("Enter email")}
						required
						autoComplete="email"
						disabled={isWorking}
					/>
				</FormRow>
				<FormRow>
					<TextField
						type="text"
						name="name"
						label={t("Name")}
						placeholder={t("What's your name")}
						required
						autoComplete="given-name"
						disabled={isWorking}
					/>
				</FormRow>
				{sourceImageKey === undefined && (
					<em>{t("Uploading image, please wait")}</em>
				)}
				<FormErrors errors={[formError]} />
				{successValues && (
					<div className={classes["success"]}>
						{t(
							"We're working on your instructions and will send them to {email} when they're ready",
							successValues,
						)}
					</div>
				)}
				<Button
					type="submit"
					variant="blue"
					form={formId}
					disabled={sourceImageKey === undefined || isWorking}
				>
					{t("Send instructions")}
				</Button>
				<Button
					type="button"
					variant="text"
					commandfor={id}
					command="close"
					disabled={isWorking}
				>
					{t("Cancel")}
				</Button>
			</form>
		</Modal>
	);
}

export default GetInstructionsModal;
