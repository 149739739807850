import classes from "./slider-input.module.css";

type SliderInputProps = {
	readonly min: number;
	readonly max: number;
	readonly step: number;
	readonly value: number;
	readonly onChange: (newValue: number) => void;
};

function SliderInput({ min, max, step, value, onChange }: SliderInputProps) {
	return (
		<div className={classes["slider-input"]}>
			<input
				// className={classes["styled-slider"]}
				type="range"
				min={min}
				max={max}
				step={step}
				value={value}
				onChange={(e) => {
					onChange(parseInt(e.currentTarget.value, 10));
				}}
			/>
			<div className={classes["current-value"]}>{value}</div>
		</div>
	);
}

export default SliderInput;
