import type { SourceImage } from "../editor/source-image.ts";

const sourceRotations = [0, 90, 180, 270] as const;

type SourceRotation = (typeof sourceRotations)[number];

type SetupConfig = {
	readonly numberOfKits: number;
	readonly sourceImage: SourceImage;
	readonly code: string | undefined;
};

export { sourceRotations };
export type { SetupConfig, SourceRotation };
