function BrightnessIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
		>
			<path
				d="M12 16.001C14.2091 16.001 16 14.2101 16 12.001C16 9.79184 14.2091 8.00098 12 8.00098C9.79086 8.00098 8 9.79184 8 12.001C8 14.2101 9.79086 16.001 12 16.001Z"
				fill="#F7B907"
			/>
			<path
				d="M9.17199 14.829C8.43564 14.0761 8.02591 13.0632 8.03171 12.0101C8.03751 10.957 8.45837 9.94866 9.20297 9.20393C9.94757 8.4592 10.9558 8.03815 12.0089 8.03217C13.062 8.02618 14.075 8.43574 14.828 9.17195L9.17199 14.829Z"
				fill="#FFF9BF"
			/>
			<path
				d="M12 16.001C14.2091 16.001 16 14.2101 16 12.001C16 9.79184 14.2091 8.00098 12 8.00098C9.79086 8.00098 8 9.79184 8 12.001C8 14.2101 9.79086 16.001 12 16.001Z"
				stroke="#3C3C3C"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M12 0.500977V4.50098"
				stroke="#3C3C3C"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M12 19.501V23.501"
				stroke="#3C3C3C"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M3.86801 3.86902L6.69701 6.69702"
				stroke="#3C3C3C"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M17.303 17.304L20.132 20.133"
				stroke="#3C3C3C"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M0.5 12.001H4.5"
				stroke="#3C3C3C"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M19.5 12.001H23.5"
				stroke="#3C3C3C"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M3.86801 20.133L6.69701 17.304"
				stroke="#3C3C3C"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M17.303 6.69702L20.132 3.86902"
				stroke="#3C3C3C"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
}

export default BrightnessIcon;
