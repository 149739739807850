import type { ApiClient } from "~/api/client.ts";
import type {
	TempMediaPresignedUrlQuery,
	TempMediaPresignedUrlQueryVariables,
} from "./temp-media-presigned-url-query.generated.ts";
import tempMediaPresignedUrlQuery from "./temp-media-presigned-url-query.graphql";

type FileOptions = {
	readonly fileName: string;
	readonly contentType: string;
	readonly content: ArrayBuffer;
};

type Options = {
	readonly signal: AbortSignal;
};

async function uploadTempFile(
	apiClient: ApiClient,
	{ fileName, contentType, content }: FileOptions,
	{ signal }: Options,
) {
	const { tempMediaPresignedUrl } = await apiClient.query<
		TempMediaPresignedUrlQuery,
		TempMediaPresignedUrlQueryVariables
	>(tempMediaPresignedUrlQuery, { fileName, contentType }, { signal });
	await fetch(tempMediaPresignedUrl.url, {
		method: "PUT",
		headers: {
			"Content-Type": contentType,
		},
		body: content,
		signal,
	});
	return tempMediaPresignedUrl.key;
}

export default uploadTempFile;
