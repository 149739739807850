function DetailIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
		>
			<path
				d="M1.50211 22.6615C1.16131 22.5 1.1232 22.0301 1.43357 21.8157C1.46294 21.7954 1.49453 21.7784 1.52755 21.765C3.86074 20.8193 3.10915 18.1609 3.80189 16.739C4.65034 15.03 6.71443 14.3197 8.43485 15.1447C13.644 17.6837 7.44317 25.4895 1.50211 22.6615Z"
				fill="white"
			/>
			<path
				d="M22.323 1.0735C21.7887 0.596865 20.9757 0.618753 20.468 1.12342L8.8151 12.7451C10.3597 13.2173 11.6431 14.3026 12.3653 15.7475L22.4846 2.92842C22.9312 2.36509 22.8603 1.55101 22.323 1.0735Z"
				fill="#C77F67"
			/>
			<path
				d="M8.43485 15.1447C8.4192 15.1369 8.4025 15.134 8.38685 15.1271C9.38438 17.8022 6.06451 21.5817 2.11555 21.4406C1.93286 21.5713 1.73558 21.6801 1.52755 21.7649C1.17802 21.9065 1.11274 22.3734 1.41014 22.6054C1.43837 22.6274 1.4688 22.6462 1.50115 22.6615C7.44317 25.4895 13.645 17.6837 8.43485 15.1447Z"
				fill="#E3E3E3"
			/>
			<path
				d="M1.50211 22.6615C1.16131 22.5 1.1232 22.0301 1.43357 21.8157C1.46294 21.7954 1.49453 21.7784 1.52755 21.765C3.86074 20.8193 3.10915 18.1609 3.80189 16.739C4.65034 15.03 6.71443 14.3197 8.43485 15.1447C13.644 17.6837 7.44317 25.4895 1.50211 22.6615Z"
				stroke="#3C3C3C"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M22.323 1.0735C21.7887 0.596865 20.9757 0.618753 20.468 1.12342L8.8151 12.7451C9.5689 12.9754 10.2686 13.355 10.8729 13.8612L22.323 1.0735Z"
				fill="#E3BFB3"
			/>
			<path
				d="M22.323 1.0735C21.7887 0.596865 20.9757 0.618753 20.468 1.12342L8.8151 12.7451C10.3597 13.2173 11.6431 14.3026 12.3653 15.7475L22.4846 2.92842C22.9312 2.36509 22.8603 1.55101 22.323 1.0735Z"
				stroke="#3C3C3C"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
}

export default DetailIcon;
