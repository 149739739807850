import type {
	BrickCounts,
	Palette,
} from "@brickme/project-core/src/model/picture.ts";
import { findOnlyOrThrow } from "@dhau/lang-extras";
import type { BrickProduct } from "~/shopify-api/context.tsx";

const packSize = 100;

function getColourBricksToBuy(
	brickCounts: BrickCounts | undefined,
	brickProducts: readonly BrickProduct[] | undefined,
	kitsPalette: Palette,
	kitPlusExtrasPalette: Palette,
) {
	return Object.entries(brickCounts ?? {})
		.filter(
			([colour]) => !kitsPalette.some((p) => p.brick.hexString === colour),
		)
		.map(([colour, { count }]) => {
			const brick = findOnlyOrThrow(
				kitPlusExtrasPalette,
				(p) => p.brick.hexString === colour,
				`Couldn't find colour ${colour} in kit plus extras palette`,
			).brick;
			const packBricksCount = packSize * Math.ceil(count / packSize);
			const packCount = packBricksCount / packSize;
			const shopifyProduct = brickProducts?.find((p) => {
				const titleComps = p.title.toLowerCase().split(" ");
				const titleLast = parseInt(titleComps[titleComps.length - 1], 10);
				return titleLast === brick.identifier;
			});
			return {
				brick,
				count,
				packCount,
				packBricksCount,
				shopifyVariant: shopifyProduct?.variants?.[0],
			};
		});
}

export { packSize, getColourBricksToBuy };
