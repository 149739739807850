import { useState } from "preact/hooks";
import ChevronUpIcon from "~/components/icons/chevron-up-icon.tsx";
import type { ModalProps } from "~/components/modal.tsx";
import Modal from "~/components/modal.tsx";
import { useTranslator } from "~/i18n/context.tsx";
import classes from "./faqs-modal.module.css";

const content = [
	{
		question: "This is some FAQ question # 1",
		answer: (
			<>
				<div>
					Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas
					sapien eros, efficitur ac iaculis in, malesuada quis purus.
				</div>
				<div>
					Praesent pellentesque ultricies vulputate. Nunc accumsan nibh ac
					fringilla tempus. Praesent dolor leo, condimentum at nulla vitae,
					aliquam tincidunt diam. Vivamus in nibh in nulla feugiat sodales.
					Proin euismod at quam ac efficitur. Curabitur aliquet mattis lacus
					eget mollis. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
				</div>
			</>
		),
	},
	{
		question: "This is some FAQ question # 2",
		answer: (
			<>
				<div>
					Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas
					sapien eros, efficitur ac iaculis in, malesuada quis purus.
				</div>
				<div>
					Praesent pellentesque ultricies vulputate. Nunc accumsan nibh ac
					fringilla tempus. Praesent dolor leo, condimentum at nulla vitae,
					aliquam tincidunt diam. Vivamus in nibh in nulla feugiat sodales.
					Proin euismod at quam ac efficitur. Curabitur aliquet mattis lacus
					eget mollis. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
				</div>
			</>
		),
	},
	{
		question: "This is some FAQ question # 3",
		answer: (
			<>
				<div>
					Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas
					sapien eros, efficitur ac iaculis in, malesuada quis purus.
				</div>
				<div>
					Praesent pellentesque ultricies vulputate. Nunc accumsan nibh ac
					fringilla tempus. Praesent dolor leo, condimentum at nulla vitae,
					aliquam tincidunt diam. Vivamus in nibh in nulla feugiat sodales.
					Proin euismod at quam ac efficitur. Curabitur aliquet mattis lacus
					eget mollis. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
				</div>
			</>
		),
	},
	{
		question: "This is some FAQ question # 4",
		answer: (
			<>
				<div>
					Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas
					sapien eros, efficitur ac iaculis in, malesuada quis purus.
				</div>
				<div>
					Praesent pellentesque ultricies vulputate. Nunc accumsan nibh ac
					fringilla tempus. Praesent dolor leo, condimentum at nulla vitae,
					aliquam tincidunt diam. Vivamus in nibh in nulla feugiat sodales.
					Proin euismod at quam ac efficitur. Curabitur aliquet mattis lacus
					eget mollis. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
				</div>
			</>
		),
	},
	{
		question: "This is some FAQ question # 5",
		answer: (
			<>
				<div>
					Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas
					sapien eros, efficitur ac iaculis in, malesuada quis purus.
				</div>
				<div>
					Praesent pellentesque ultricies vulputate. Nunc accumsan nibh ac
					fringilla tempus. Praesent dolor leo, condimentum at nulla vitae,
					aliquam tincidunt diam. Vivamus in nibh in nulla feugiat sodales.
					Proin euismod at quam ac efficitur. Curabitur aliquet mattis lacus
					eget mollis. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
				</div>
			</>
		),
	},
];

type FaqsModalProps = Pick<ModalProps, "id">;

function FaqsModal({ id }: FaqsModalProps) {
	const [activeIndex, setActiveIndex] = useState<number | undefined>();
	const t = useTranslator();
	return (
		<Modal id={id} size="large" bodyClassName={classes["faqs-body"]}>
			<h2 className="heading-2">{t("FAQs")}</h2>
			<div>
				{content.map((c, i) => (
					<details
						open={activeIndex === i}
						onToggle={(e) =>
							setActiveIndex((p) => {
								if (e.currentTarget.open) {
									return i;
								}
								if (p === i) {
									return undefined;
								}
								return p;
							})
						}
					>
						<summary>
							{c.question} <ChevronUpIcon />
						</summary>
						<div className={classes["answer"]}>{c.answer}</div>
					</details>
				))}
			</div>
		</Modal>
	);
}

export default FaqsModal;
