import type { ReactNode } from "preact/compat";
import type useForm from "~/hooks/use-form.ts";
import Button from "./button.tsx";
import SummaryText from "./summary-text.tsx";
import FormErrors from "./forms/form-errors.tsx";
import classes from "./simple-form-card.module.css";

type SimpleFormCardProps = {
	readonly heading: string;
	readonly summary: string;
	readonly form: ReturnType<typeof useForm>;
	readonly submitLabel: string;
	readonly icon: ReactNode;
	readonly children: ReactNode;
};

function SimpleFormCard({
	submitLabel,
	heading,
	summary,
	form: { formProps, isWorking, formError },
	icon,
	children,
}: SimpleFormCardProps) {
	return (
		<form {...formProps} className={classes["card"]}>
			{icon}
			<h1 className="heading-1">{heading}</h1>
			<SummaryText className={classes["summary"]}>{summary}</SummaryText>
			{children}
			<FormErrors errors={[formError]} />
			<Button type="submit" variant="blue" working={isWorking} fullWidth>
				{submitLabel}
			</Button>
		</form>
	);
}

export default SimpleFormCard;
