import { useTranslator } from "~/i18n/context.tsx";
import Button from "~/components/button.tsx";
import BasketLineIcon from "~/components/icons/basket-line.tsx";
import BuyAKitModal from "./buy-a-kit-modal.tsx";
import classes from "./try-it-out-complete-menu.module.css";

const buyAKitModalId = "buy-a-kit-modal";

type TryItOutCompleteMenuProps = {
	readonly onBackToHome: () => void;
};

function TryItOutCompleteMenu({ onBackToHome }: TryItOutCompleteMenuProps) {
	const t = useTranslator();
	return (
		<>
			<BuyAKitModal id={buyAKitModalId} />
			<div className={classes["bottom-actions"]}>
				<Button
					type="button"
					variant="red"
					commandfor={buyAKitModalId}
					command="show-modal"
				>
					<BasketLineIcon /> {t("Buy a Kit")}
				</Button>
				<Button type="button" variant="text" onClick={onBackToHome}>
					{t("Back to Home")}
				</Button>
			</div>
		</>
	);
}

export default TryItOutCompleteMenu;
