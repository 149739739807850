import { useEffect, useRef, useState } from "preact/hooks";
import useSlider from "react-use/lib/useSlider";
import { pick } from "lodash-es";
import { useTranslator } from "~/i18n/context.tsx";
import initialImage from "./initial-image.png?format=webp&width=432&height=324&as=metadata";
import afterImage from "./colourised-image.png?format=webp&width=432&height=324&as=metadata";
import classes from "./comparison-image.module.css";
import HandIcon from "./hand-icon.tsx";

function ComparisonImage() {
	const imageRef = useRef<HTMLDivElement>(null);
	const { isSliding, value: sliderValue } = useSlider(imageRef);
	const t = useTranslator();

	// No way to give useSlider a default value (it's set to 0)
	const [hasStartedSliding, setStartedSliding] = useState(false);
	useEffect(() => {
		if (isSliding && !hasStartedSliding) {
			setStartedSliding(true);
		}
	}, [isSliding, hasStartedSliding]);
	const position = hasStartedSliding ? sliderValue : 0.5;

	return (
		<div
			ref={imageRef}
			className={classes["comparison-image"]}
			style={{
				"--position": `${Math.round(position * 100)}%`,
			}}
		>
			<button
				type="button"
				className={classes["comparison-image-slider-handle"]}
			>
				<HandIcon />
			</button>
			<div className={classes["images-container"]}>
				<div className={classes["before-image"]}>
					<img
						{...pick(initialImage, "src", "width", "height")}
						alt={t("Initial image")}
					/>
				</div>
				<div className={classes["after-image"]}>
					<div className={classes["after-image-inner"]}>
						<img
							{...pick(afterImage, "src", "width", "height")}
							alt={t("Image with colour")}
						/>
					</div>
				</div>
				<div className={classes["comparison-image-slider-track"]} />
			</div>
		</div>
	);
}

export default ComparisonImage;
