import type { HTMLAttributes } from "preact/compat";

type ChatEnhancedIconProps = Pick<HTMLAttributes<SVGSVGElement>, "className">;

function ChatEnhancedIcon({ className }: ChatEnhancedIconProps) {
	return (
		<svg
			width="44"
			height="44"
			viewBox="0 0 44 44"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={className}
		>
			<path
				d="M42.6617 31.8817H10.3217L1.33838 40.865V3.13501H42.6617V31.8817Z"
				fill="#E83044"
			/>
			<path
				d="M8.52505 28.2883L1.33838 35.475V40.865L10.3217 31.8817H42.6617V28.2883H8.52505Z"
				fill="white"
			/>
			<path
				d="M42.6617 31.8817H10.3217L1.33838 40.865V3.13501H42.6617V31.8817Z"
				stroke="#3C3C3C"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M11.6954 16L9 18.6963L11.695 21.3904L14.3906 18.6941L11.6954 16Z"
				fill="white"
			/>
			<path
				d="M22.9 15.7114L20.2046 18.4076L22.8996 21.1017L25.595 18.4054L22.9 15.7114Z"
				fill="white"
			/>
			<path
				d="M33.6785 15.7134L30.9844 18.4086L33.6794 21.1026L36.3735 18.4075L33.6785 15.7134Z"
				fill="white"
			/>
			<path
				d="M12.1182 15.7117L9.42285 18.408L12.1179 21.102L14.8134 18.4058L12.1182 15.7117Z"
				stroke="#3C3C3C"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M22.9 15.7114L20.2046 18.4076L22.8996 21.1017L25.595 18.4054L22.9 15.7114Z"
				stroke="#3C3C3C"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M33.6785 15.7134L30.9844 18.4086L33.6794 21.1026L36.3735 18.4075L33.6785 15.7134Z"
				stroke="#3C3C3C"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

export default ChatEnhancedIcon;
