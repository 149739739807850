import { useTranslator } from "~/i18n/context.tsx";
import Button from "~/components/button.tsx";
import type { Mode } from "../mode.ts";
import RemoveColourModal from "./remove-colour-modal.tsx";
import BuyColourBricksModal from "./buy-colour-bricks-modal.tsx";
import GetInstructionsModal from "./get-instructions-modal.tsx";
import { usePalettes, usePicture } from "../context.tsx";
import { createDefaultPaletteMode } from "../create-default-picture-configuration.ts";
import classes from "./complete-colours-menu.module.css";

const removeColourModalId = "remove-colour-modal";
const buyColourBricksModalId = "buy-colour-bricks-modal";
const getInstructionsModalId = "get-instructions-modal";

type CompleteColoursMenuProps = {
	readonly code: string;
	readonly numberOfKits: number;
	readonly onChangeMode: (mode: Mode) => void;
};

function CompleteColoursMenu({
	code,
	onChangeMode,
	numberOfKits,
}: CompleteColoursMenuProps) {
	const t = useTranslator();
	const { patchPicture } = usePicture();
	const { kitsPalette } = usePalettes();
	return (
		<>
			<RemoveColourModal
				id={removeColourModalId}
				onConfirm={() => {
					onChangeMode("kit-only");
					patchPicture({
						paletteMode: createDefaultPaletteMode({ kitsPalette }),
					});
				}}
			/>
			<BuyColourBricksModal id={buyColourBricksModalId} />
			<GetInstructionsModal
				id={getInstructionsModalId}
				numberOfKits={numberOfKits}
				code={code}
			/>
			<div className={classes["bottom-actions"]}>
				<Button
					type="button"
					variant="blue"
					commandfor={getInstructionsModalId}
					command="show-modal"
				>
					{t("Get instructions")}
				</Button>
				<Button
					type="button"
					variant="black-line-blue-text"
					commandfor={buyColourBricksModalId}
					command="show-modal"
				>
					{t("Buy colour bricks")}
				</Button>
				<Button
					type="button"
					variant="text-danger"
					commandfor={removeColourModalId}
					command="show-modal"
				>
					{t("Remove colour")}
				</Button>
			</div>
		</>
	);
}

export default CompleteColoursMenu;
