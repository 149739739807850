import ArrowLeftIcon from "./arrow-left.tsx";

type ArrowRightIconProps = {
	readonly size: number;
};

function ArrowRightIcon(props: ArrowRightIconProps) {
	return <ArrowLeftIcon style={{ transform: "rotate(180deg)" }} {...props} />;
}

export default ArrowRightIcon;
