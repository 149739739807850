import { clsx } from "clsx";
import type { ReactNode } from "preact/compat";
import classes from "./summary-text.module.css";

type SummaryTextProps = {
	readonly className?: string;
	readonly children: ReactNode;
};

function SummaryText({ className, children }: SummaryTextProps) {
	return (
		<div className={clsx(classes["summary-text"], className)}>{children}</div>
	);
}

export default SummaryText;
