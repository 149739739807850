import { pick } from "lodash-es";
import type { ModalProps } from "~/components/modal.tsx";
import { useTranslator } from "~/i18n/context.tsx";
import CloseIcon from "~/components/icons/cross.tsx";
import Button from "~/components/button.tsx";
import PaletteIcon from "./palette-icon.tsx";
import backgroundImageLarge from "./background-lg.png?width=2732&height=2048&format=webp&quality=93&as=metadata";
import backgroundImageMedium from "./background-md.png?width=1366&height=1024&format=webp&quality=87&as=metadata";
import classes from "./add-colour-modal.module.css";
import ComparisonImage from "./comparison-image.tsx";

type AddColourModalProps = Pick<ModalProps, "id"> & {
	readonly onConfirm: () => void;
};

function AddColourModal({ id, onConfirm }: AddColourModalProps) {
	const t = useTranslator();
	return (
		<dialog id={id} className={classes["add-colour-modal"]}>
			<picture>
				<source
					srcSet={backgroundImageLarge.src}
					media={`(min-width: ${backgroundImageMedium.width}px)`}
				/>
				<img
					{...pick(backgroundImageMedium, "src", "width", "height")}
					alt={t("Colourful lego bricks")}
					className={classes["background"]}
				/>
			</picture>
			<button
				className={classes["close-button"]}
				aria-label={t("Close")}
				type="button"
				commandfor={id}
				command="close"
			>
				<CloseIcon />
			</button>
			<div className={classes["content"]}>
				<PaletteIcon />
				<h1 className="heading-1">{t("Enhance your creation with colours")}</h1>
				<div className={classes["summary"]}>
					{t(
						`You can now add colour to your brick mosaic by ordering additional coloured bricks! The next step will take you back to the design with extra colour editing options. The additional cost will be calculated and shown before you proceed with the order.`,
					)}
				</div>
				<div className={classes["colourised-image"]}>
					<ComparisonImage />
				</div>
				<div className={classes["buttons"]}>
					<button
						type="button"
						onClick={onConfirm}
						className={classes["confirm-button"]}
						aria-label={t("Let's add colours")}
					>
						<div className={classes["confirm-button-text"]}>
							{t("Let's add colours")}
						</div>
					</button>
					<Button type="button" variant="text" commandfor={id} command="close">
						{t("Nah, I prefer black & white.")}
					</Button>
				</div>
			</div>
		</dialog>
	);
}

export default AddColourModal;
