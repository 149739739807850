import { clsx } from "clsx";
import { useState } from "preact/hooks";
import type { FormValues } from "~/hooks/use-form.ts";
import useForm from "~/hooks/use-form.ts";
import { useTranslator } from "~/i18n/context.tsx";
import Button from "~/components/button.tsx";
import TextField from "~/components/forms/text-field.tsx";
import FormErrors from "~/components/forms/form-errors.tsx";
import SimplePage from "~/components/simple-page.tsx";
import SummaryText from "~/components/summary-text.tsx";
import SingleButtonBottomSheet from "~/components/sheet/single-button-bottom-sheet.tsx";
import oneKitImage from "./1-kit.png?w=112&h=111&format=webp&as=metadata";
import twoKitsImage from "./2-kits.png?w=125&h=111&format=webp&as=metadata";
import threeKitsImage from "./3-kits.png?w=126&h=111&format=webp&as=metadata";
import moreThanThreeKitsImage from "./more-than-3-kits.png?w=137&h=111&format=webp&as=metadata";
import ChooseIcon from "./choose-icon.tsx";
import SelectedIcon from "./selected-icon.tsx";
import classes from "./enter-number-of-kits.module.css";

const otherNumberOfKitsValue = "other";

const formId = "enter-number-of-kits-form";

const kitOptions = [
	{ image: oneKitImage, label: "1 Kit", value: "1" },
	{ image: twoKitsImage, label: "2 Kits", value: "2" },
	{ image: threeKitsImage, label: "3 Kits", value: "3" },
	{
		image: moreThanThreeKitsImage,
		label: "More than 3 kits",
		imageLayout: "right",
		value: otherNumberOfKitsValue,
	},
];

type EnterNumberOfKitsProps = {
	readonly onComplete: (numberOfKits: number) => void;
	readonly onCancel: () => void;
};

function EnterNumberOfKits({ onComplete, onCancel }: EnterNumberOfKitsProps) {
	const t = useTranslator();

	const { isWorking, formError, formProps } = useForm({
		id: formId,
		onSubmit({ numberOfKits: rawNumberOfKits, otherNumberOfKits }: FormValues) {
			const inputNumberOfKits =
				rawNumberOfKits === otherNumberOfKitsValue
					? otherNumberOfKits
					: rawNumberOfKits;
			onComplete(parseInt(inputNumberOfKits as string, 10));
		},
	});

	const [numberOfKitsOption, setNumberOfKitsOption] = useState<string>();

	return (
		<SimplePage
			onBackClick={onCancel}
			bottomSheet={
				<SingleButtonBottomSheet>
					<FormErrors errors={[formError]} />
					<Button
						type="submit"
						variant="blue"
						form={formId}
						working={isWorking}
					>
						{t("Submit")}
					</Button>
				</SingleButtonBottomSheet>
			}
		>
			<form {...formProps} className={classes["form"]}>
				<div className={classes["main-text"]}>
					<h1 className="heading-1">{t("How many kits?")}</h1>
					<SummaryText>
						{t(`You can use additional kits to create larger brick mosaics`)}
					</SummaryText>
				</div>
				<fieldset role="radiogroup" className={classes["options"]}>
					{kitOptions.map((k) => {
						const optionFieldId = `number-of-kits-radio-${k.value}`;
						return (
							<label key={k.value} htmlFor={optionFieldId}>
								<input
									id={optionFieldId}
									name="numberOfKits"
									type="radio"
									value={k.value}
									required
									checked={numberOfKitsOption === k.value}
									onChange={(e) => {
										if (e.currentTarget.checked) {
											setNumberOfKitsOption(k.value);
										}
									}}
									disabled={isWorking}
								/>
								<div
									className={clsx(
										classes["option-image"],
										k.imageLayout === "right" && classes["right-image-layout"],
									)}
								>
									<img
										src={k.image.src}
										width={k.image.width}
										height={k.image.height}
										alt={k.label}
									/>
									<ChooseIcon
										className={clsx(
											classes["option-indicator"],
											classes["not-selected-option-indicator"],
										)}
									/>
									<SelectedIcon
										className={clsx(
											classes["option-indicator"],
											classes["selected-option-indicator"],
										)}
									/>
								</div>
								<div className={classes["option-text"]}>{k.label}</div>
							</label>
						);
					})}
				</fieldset>
				{numberOfKitsOption === otherNumberOfKitsValue && (
					<TextField
						name="otherNumberOfKits"
						type="number"
						min="1"
						max="100"
						step="1"
						label={t("Enter number of kits")}
						disabled={isWorking}
						required
						className={classes["number-of-kits-input"]}
						placeholder="e.g. 4, 5 or 6"
					/>
				)}
			</form>
		</SimplePage>
	);
}

export default EnterNumberOfKits;
