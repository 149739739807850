import type { HTMLAttributes } from "preact/compat";

type ArrowLeftIconProps = Pick<HTMLAttributes<SVGSVGElement>, "style"> & {
	readonly size: number;
};

function ArrowLeftIcon({ size, ...props }: ArrowLeftIconProps) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			viewBox="0 0 16 16"
			fill="currentColor"
			{...props}
		>
			<path d="M0.25 8.60735C0.0833333 8.44068 0 8.2383 0 8.00021C0 7.76211 0.0833333 7.55973 0.25 7.39307L6.53571 1.39307C6.96429 1.05973 7.36905 1.05973 7.75 1.39307C8.08333 1.82164 8.07143 2.2264 7.71429 2.60735L3 7.14307H15.1429C15.6667 7.19069 15.9524 7.4764 16 8.00021C15.9524 8.52402 15.6667 8.80973 15.1429 8.85735H3L7.75 13.3931C8.08333 13.774 8.08333 14.1788 7.75 14.6074C7.36905 14.9407 6.97619 14.9407 6.57143 14.6074L0.285714 8.60735H0.25Z" />
		</svg>
	);
}

export default ArrowLeftIcon;
