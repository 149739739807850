import { useState } from "preact/hooks";
import { ensureExhaustive } from "@dhau/lang-extras";
import type { SetupConfig } from "./config.ts";
import UploadImage from "./upload/upload-image.tsx";
import ImageConfirm from "./image-confirm/image-confirm.tsx";
import rotateSourceImage from "./rotate-source-image.ts";

type UploadImageStep = {
	readonly type: "upload-image";
};

type ImageConfirmStep = {
	readonly type: "image-confirm";
	readonly progress: Pick<SetupConfig, "sourceImage">;
};

type SetupTryProps = {
	readonly onComplete: (config: SetupConfig) => void;
	readonly onCancel: () => void;
};

function SetupTry({ onComplete, onCancel }: SetupTryProps) {
	const [step, setStep] = useState<UploadImageStep | ImageConfirmStep>({
		type: "upload-image",
	});
	switch (step.type) {
		case "upload-image":
			return (
				<UploadImage
					onComplete={(sourceImage) =>
						setStep({ type: "image-confirm", progress: { sourceImage } })
					}
					onCancel={onCancel}
				/>
			);
		case "image-confirm":
			return (
				<ImageConfirm
					sourceImage={step.progress.sourceImage}
					onComplete={async (rotation) =>
						onComplete({
							numberOfKits: 1,
							code: undefined,
							...step.progress,
							sourceImage: await rotateSourceImage(
								step.progress.sourceImage,
								rotation,
							),
						})
					}
					onCancel={() => setStep({ type: "upload-image" })}
				/>
			);
		default:
			ensureExhaustive(step);
	}
}

export default SetupTry;
