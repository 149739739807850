function UploadIcon() {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M3 1.5C2.15625 1.53125 1.45312 1.82812 0.890625 2.39062C0.328125 2.95312 0.03125 3.65625 0 4.5V19.5C0.03125 20.3438 0.328125 21.0469 0.890625 21.6094C1.45312 22.1719 2.15625 22.4688 3 22.5H21C21.8438 22.4688 22.5469 22.1719 23.1094 21.6094C23.6719 21.0469 23.9688 20.3438 24 19.5V7.5C23.9688 6.65625 23.6719 5.95312 23.1094 5.39062C22.5469 4.82812 21.8438 4.53125 21 4.5H13.5938L11.5781 2.48438C10.9219 1.82812 10.125 1.5 9.1875 1.5H3ZM2.25 4.5C2.28125 4.03125 2.53125 3.78125 3 3.75H9.1875C9.5 3.75 9.76562 3.85938 9.98438 4.07812L12.0938 6.1875C12.4688 6.5625 12.9219 6.75 13.4531 6.75H21C21.4688 6.78125 21.7188 7.03125 21.75 7.5V19.5C21.7188 19.9688 21.4688 20.2188 21 20.25H3C2.53125 20.2188 2.28125 19.9688 2.25 19.5V4.5ZM13.125 17.625V12.8438L14.5781 14.2969C15.1094 14.7344 15.6406 14.7344 16.1719 14.2969C16.6094 13.7656 16.6094 13.2344 16.1719 12.7031L12.7969 9.32812C12.2656 8.89062 11.7344 8.89062 11.2031 9.32812L7.82812 12.7031C7.39062 13.2344 7.39062 13.7656 7.82812 14.2969C8.35938 14.7344 8.89062 14.7344 9.42188 14.2969L10.875 12.8438V17.625C10.9375 18.3125 11.3125 18.6875 12 18.75C12.6875 18.6875 13.0625 18.3125 13.125 17.625Z"
				fill="currentColor"
			/>
		</svg>
	);
}

export default UploadIcon;
