function BlueBrickIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="38"
			height="33"
			viewBox="0 0 38 33"
			fill="none"
		>
			<path
				d="M1 11.1201L18.42 19.0401L37.06 10.8801L19.21 2.87012C12.1 6.09012 8.11 7.90012 1 11.1201Z"
				fill="white"
			/>
			<path
				d="M18.42 19.0301L1 11.1201V23.7901L18.42 31.7101V19.0401V19.0301Z"
				fill="white"
			/>
			<path
				d="M37.4199 11.1201L18.4199 19.0401V31.7101L37.2099 23.6301L37.4199 11.1301V11.1201Z"
				fill="#0F6DDA"
			/>
			<path
				d="M9.22998 14.9201L18.41 19.0401L37.05 10.8801L28.2 6.87012L9.22998 14.9201Z"
				fill="#B2D0F3"
			/>
			<path
				d="M19.21 2.87012L1 11.1201"
				stroke="#3C3C3C"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M37.21 10.8701L19.21 2.87012"
				stroke="#3C3C3C"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M37.2099 10.96L18.4199 19.04V31.71L37.2099 23.63V10.96Z"
				stroke="#3C3C3C"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M18.42 19.0301L1 11.1201V23.7901L18.42 31.7101V19.0401V19.0301Z"
				stroke="#3C3C3C"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M26.3702 4.56982V9.78982C26.3702 11.7698 22.8102 13.3698 18.4202 13.3698C14.0302 13.3698 10.4702 11.7698 10.4702 9.78982V4.56982C10.4702 6.54982 14.0302 8.14982 18.4202 8.14982C22.8102 8.14982 26.3702 6.54982 26.3702 4.56982Z"
				fill="#0F6DDA"
			/>
			<path
				d="M18.4202 1C22.8102 1 26.3702 2.6 26.3702 4.57C26.3702 6.54 22.8102 8.15 18.4202 8.15C14.0302 8.15 10.4702 6.55 10.4702 4.57C10.4702 2.59 14.0302 1 18.4202 1Z"
				fill="white"
			/>
			<path
				d="M26.3702 9.79004C26.3702 11.77 22.8102 13.37 18.4202 13.37C14.0302 13.37 10.4702 11.77 10.4702 9.79004"
				stroke="#3C3C3C"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M26.3702 4.57C26.3702 6.55 22.8102 8.15 18.4202 8.15C14.0302 8.15 10.4702 6.55 10.4702 4.57C10.4702 2.59 14.0302 1 18.4202 1C22.8102 1 26.3702 2.6 26.3702 4.57Z"
				stroke="#3C3C3C"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M10.4702 4.56982V9.78982"
				stroke="#3C3C3C"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M26.3701 4.56982V9.78982"
				stroke="#3C3C3C"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
}

export default BlueBrickIcon;
