import type { ReactNode } from "preact/compat";
import BottomSheet from "./bottom-sheet.tsx";
import classes from "./confirm-sheet.module.css";
import Button from "../button.tsx";

type ConfirmSheetProps = {
	readonly open: boolean;
	readonly onCancel: () => void;
	readonly onDone: () => void;
	readonly title: string;
	readonly children: ReactNode;
};

function ConfirmSheet({
	open,
	onCancel,
	onDone,
	title,
	children,
}: ConfirmSheetProps) {
	return (
		<BottomSheet open={open}>
			<div className={classes["confirm-sheet"]}>
				<h4>{title}</h4>
				<div className={classes["content"]}>{children}</div>
				<div className={classes["actions"]}>
					<Button type="button" onClick={onCancel} variant="black-line">
						Cancel
					</Button>
					<Button type="button" onClick={onDone} variant="blue">
						Done
					</Button>
				</div>
			</div>
		</BottomSheet>
	);
}

export type { ConfirmSheetProps };
export default ConfirmSheet;
