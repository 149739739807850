function MouseScrollIcon() {
	return (
		<svg
			width="48"
			height="101"
			viewBox="0 0 48 101"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect
				x="21.6001"
				y="40.0859"
				width="4.79998"
				height="9.59995"
				rx="2.39999"
				fill="url(#paint0_radial_376_2230)"
			/>
			<path
				d="M30.9551 26.52H17.0432C7.64608 26.52 0 34.1661 0 43.5633V76.2606C0 89.494 10.7658 100.26 24.0006 100.26C37.2355 100.26 48.0012 89.494 48.0012 76.2606V43.5633C48.0012 34.1646 40.3551 26.5186 30.9565 26.5186L30.9551 26.52ZM44.6984 43.5633V56.0684H25.6506V52.6556C27.8456 51.9684 29.4428 49.9155 29.4428 47.4977V42.602C29.4428 40.1827 27.8456 38.1313 25.6506 37.444V29.8214H30.9551C38.5323 29.8214 44.6969 35.986 44.6969 43.5633H44.6984ZM24.0387 40.5007C25.1978 40.5007 26.1415 41.4444 26.1415 42.6035V47.4991C26.1415 48.6582 25.1978 49.6019 24.0387 49.6019C22.8796 49.6019 21.936 48.6582 21.936 47.4991V42.6035C21.936 41.4444 22.8796 40.5007 24.0387 40.5007ZM17.0447 29.8214H22.3492V37.4704C20.1952 38.1811 18.6346 40.212 18.6346 42.602V47.4977C18.6346 49.8876 20.1937 51.9186 22.3492 52.6292V56.0684H3.30138V43.5633C3.30138 35.986 9.46602 29.8214 17.0432 29.8214H17.0447ZM24.0006 96.9598C12.5872 96.9598 3.30285 87.674 3.30285 76.2621V59.3712H44.6984V76.2621C44.6984 87.6755 35.4126 96.9598 24.0006 96.9598Z"
				fill="#E5E5E5"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M32.2158 8.66001C29.7111 6.54616 26.9167 3.70838 24.8897 1.07331C24.7884 0.943 24.6581 0.885091 24.4988 0.885091C24.3396 0.885091 24.1948 0.957478 24.1079 1.07331C22.0809 3.72286 19.2721 6.54616 16.7818 8.66002C16.5791 8.83376 16.4922 9.06541 16.5357 9.32602C16.6515 9.9486 17.2596 11.0779 17.7808 11.4833C17.9835 11.6426 18.2441 11.6715 18.4903 11.5702C19.3155 11.2227 20.4593 10.6436 21.5887 9.93412C21.7045 13.1918 21.9361 17.5932 22.1388 20.1559C22.1678 20.5179 22.3995 20.793 22.7325 20.8798C23.2102 21.0101 23.8328 21.0825 24.4988 21.0825C25.1648 21.0825 25.7874 21.0101 26.2652 20.8798C26.5982 20.793 26.8299 20.5034 26.8588 20.1559C27.076 17.5932 27.2932 13.1918 27.409 9.93412C28.5383 10.6436 29.6821 11.2227 30.5074 11.5702C30.7535 11.6715 31.0141 11.6426 31.2168 11.4833C31.7381 11.0779 32.3461 9.9486 32.462 9.32602C32.5054 9.06541 32.4185 8.81928 32.2158 8.66001Z"
				fill="#F7B907"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M16.7815 77.3239C19.2862 79.4377 22.0806 82.2755 24.1076 84.9106C24.2089 85.0409 24.3392 85.0988 24.4985 85.0988C24.6577 85.0988 24.8025 85.0264 24.8894 84.9106C26.9164 82.261 29.7252 79.4377 32.2155 77.3239C32.4182 77.1501 32.5051 76.9185 32.4616 76.6579C32.3458 76.0353 31.7377 74.906 31.2165 74.5006C31.0138 74.3413 30.7532 74.3124 30.507 74.4137C29.6818 74.7612 28.538 75.3403 27.4087 76.0498C27.2928 72.7921 27.0612 68.3907 26.8585 65.828C26.8295 65.466 26.5979 65.1909 26.2649 65.1041C25.7871 64.9738 25.1645 64.9014 24.4985 64.9014C23.8325 64.9014 23.2099 64.9738 22.7321 65.1041C22.3991 65.1909 22.1675 65.4805 22.1385 65.828C21.9213 68.3907 21.7041 72.7921 21.5883 76.0498C20.459 75.3403 19.3152 74.7612 18.4899 74.4137C18.2438 74.3124 17.9832 74.3413 17.7805 74.5006C17.2593 74.906 16.6512 76.0353 16.5353 76.6579C16.4919 76.9185 16.5788 77.1646 16.7815 77.3239Z"
				fill="#F7B907"
			/>
			<defs>
				<radialGradient
					id="paint0_radial_376_2230"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(24.0001 44.8859) rotate(90) scale(4.79998 2.39999)"
				>
					<stop stop-color="#FFB2BA" />
					<stop offset="1" stop-color="#E82F41" />
				</radialGradient>
			</defs>
		</svg>
	);
}

export default MouseScrollIcon;
