type CrossIconProps = {
	readonly size?: number;
};

function CrossIcon({ size = 22 }: CrossIconProps) {
	return (
		<svg
			viewBox="-1 -1 22 22"
			xmlns="http://www.w3.org/2000/svg"
			height={size}
			width={size}
			stroke="currentColor"
		>
			<path
				d="m0.625 19.374166666666664 18.75 -18.75"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="2"
			></path>
			<path
				d="M19.375 19.374166666666664 0.625 0.6241666666666666"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="2"
			></path>
		</svg>
	);
}

export default CrossIcon;
