import { GraphQLClient } from "graphql-request";
import { createContext } from "preact";
import type { ReactNode } from "preact/compat";
import { useContext, useEffect, useMemo, useState } from "preact/hooks";

const context = createContext<ShopifyValue | undefined>(undefined);

type ShopifyPrice = {
	readonly amount: string;
	readonly currencyCode: string;
};

type ProductsQuery = {
	readonly products: {
		readonly nodes: readonly {
			readonly title: string;
			readonly variants: {
				readonly nodes: readonly {
					readonly id: string;
					readonly price: ShopifyPrice;
				}[];
			};
		}[];
	};
};

type BrickProduct = {
	readonly title: string;
	readonly variants: readonly {
		readonly id: string;
		readonly price: ShopifyPrice;
	}[];
};

type ShopifyValue = {
	readonly brickProducts: readonly BrickProduct[] | undefined;
	readonly setColoursRequired: () => void;
};

type ShopifyApiProviderProps = {
	readonly shopDomain: string;
	readonly storefrontAccessToken: string;
	readonly children: ReactNode;
};

function ShopifyApiProvider({
	shopDomain,
	storefrontAccessToken,
	children,
}: ShopifyApiProviderProps) {
	const shopifyClient = useMemo(
		() =>
			new GraphQLClient(`https://${shopDomain}/api/2025-01/graphql.json`, {
				headers: {
					"X-Shopify-Storefront-Access-Token": storefrontAccessToken,
				},
			}),
		[shopDomain, storefrontAccessToken],
	);

	const [coloursRequired, setColoursRequired] = useState(false);
	const [brickProducts, setBrickProducts] = useState<readonly BrickProduct[]>();

	// Loading bricks
	useEffect(() => {
		if (!coloursRequired) {
			return;
		}

		(async () => {
			const { products } = await shopifyClient.request<ProductsQuery>(
				// TODO: Graphql codegen
				`
                query bricksProducts($query: String!, $countryCode: CountryCode!)
                    @inContext(country: $countryCode) {
                        products(first: 100, query: $query) {
                            nodes {
                                title
                                variants(first: 100) {
                                    nodes {
                                        id
                                        price {
                                            amount
                                            currencyCode
                                        }
                                    }
                                }
                            }
                        }
                    }
                `,
				{
					countryCode: "AU",
					query: "tag:bricks",
				},
			);
			setBrickProducts(
				products.nodes.map((p) => ({
					title: p.title,
					variants: p.variants.nodes.map((v) => ({
						...v,
						id: v.id.replace("gid://shopify/ProductVariant/", ""),
					})),
				})),
			);
		})();
	}, [coloursRequired, shopifyClient]);

	const value = useMemo(
		() => ({
			brickProducts,
			setColoursRequired: () => setColoursRequired(true),
		}),
		[brickProducts],
	);

	return <context.Provider value={value}>{children}</context.Provider>;
}

function useShopify() {
	const value = useContext(context);
	if (value === undefined) {
		throw new Error("No shopify context");
	}
	return value;
}

export type { ShopifyPrice, BrickProduct, ShopifyApiProviderProps };
export { useShopify };
export default ShopifyApiProvider;
