import { useMemo, useState } from "preact/hooks";
import { pick, sum } from "lodash-es";
import { clsx } from "clsx";
import { useTranslator } from "~/i18n/context.tsx";
import CrossIcon from "~/components/icons/cross.tsx";
import useLocalStorage from "~/hooks/use-local-storage.ts";
import type { ShopifyPrice } from "~/shopify-api/context.tsx";
import { useShopify } from "~/shopify-api/context.tsx";
import BrickIcon from "./brick-icon.tsx";
import IconTaggedText from "./icon-tagged-text.tsx";
import DownIcon from "./down-icon.tsx";
import { useCurrentBrickedBuild, usePalettes } from "../context.tsx";
import AmountPill from "./amount-pill.tsx";
import handleImage from "./summary-handle.png?width=118&height=17&format=webp&as=metadata";
import { getColourBricksToBuy, packSize } from "~/utils/colour-bricks.ts";
import classes from "./colours-cost-summary.module.css";

function ColoursCostSummary() {
	const [open, setOpen] = useState(false);

	const t = useTranslator();
	const build = useCurrentBrickedBuild();
	const { kitsPalette, kitPlusExtrasPalette } = usePalettes();
	const { brickProducts } = useShopify();
	const brickCounts = build?.brickCounts;
	const orderBricks = useMemo(() => {
		const bricks = getColourBricksToBuy(
			brickCounts,
			brickProducts,
			kitsPalette,
			kitPlusExtrasPalette,
		).sort((c1, c2) => c1.brick.identifier - c2.brick.identifier);
		return {
			brickCounts: bricks,
			totalAmount: sum(bricks.map((c) => c.count)),
			totalPackBricksAmount: sum(bricks.map((c) => c.packBricksCount)),
			totalPacksAmount: sum(bricks.map((c) => c.packCount)),
			totalPrice:
				brickProducts === undefined || bricks.length === 0
					? undefined
					: bricks.reduce(
							(
								accu,
								{ packCount, shopifyVariant },
							): ShopifyPrice | undefined => {
								if (shopifyVariant === undefined) {
									return accu;
								}

								const { price } = shopifyVariant;
								if (accu === undefined) {
									return price;
								}

								if (accu.currencyCode !== price.currencyCode) {
									throw new Error(
										`Received mixed currency codes ${accu.currencyCode} and ${price.currencyCode}`,
									);
								}
								return {
									amount: (
										parseFloat(accu.amount) +
										packCount * parseFloat(price.amount)
									).toString(),
									currencyCode: accu.currencyCode,
								};
							},
							undefined as ShopifyPrice | undefined,
						),
		};
	}, [brickCounts, brickProducts]);

	const [hideBricksNotice, setHideBricksNotice] = useLocalStorage(
		"hideOrderBricksNotice",
		false,
		JSON.parse,
		JSON.stringify,
	);

	return (
		<div
			className={clsx(
				classes["colours-cost-summary"],
				open && classes["colours-cost-summary-open"],
			)}
		>
			<div className={classes["colours-cost-summary-header-container"]}>
				<div className={classes["colours-cost-summary-header"]}>
					<div
						className={clsx(
							classes["colours-cost-summary-header-side"],
							classes["colours-cost-summary-header-price"],
						)}
					>
						<IconTaggedText
							icon={<DownIcon />}
							prefixText={
								orderBricks.totalPrice
									? orderBricks.totalPrice.currencyCode
									: undefined
							}
							text={
								orderBricks.totalPrice
									? "$" + orderBricks.totalPrice.amount
									: "--"
							}
						/>
					</div>
					<button
						type="button"
						className={classes["show-summary"]}
						onClick={() => setOpen(true)}
					>
						{t("Show summary")}
					</button>
					<div
						className={clsx(
							classes["colours-cost-summary-header-side"],
							classes["colours-cost-summary-header-bricks"],
						)}
					>
						<IconTaggedText
							icon={<BrickIcon />}
							text={orderBricks.totalAmount.toLocaleString()}
						/>
					</div>
				</div>
			</div>
			<div className={classes["body-positioner"]}>
				<div className={classes["body-container"]}>
					<div className={classes["body"]}>
						<div className={classes["colours-heading"]}>
							{t("Colours")} ({orderBricks.brickCounts.length})
						</div>
						{!hideBricksNotice && (
							<div className={classes["brick-packs-notice"]}>
								<div />
								<div>
									{t("Note: Bricks always come in")}{" "}
									<strong>
										{t("packs of {amount}", { amount: packSize })}
									</strong>
								</div>
								<button type="button" onClick={() => setHideBricksNotice(true)}>
									<CrossIcon size={12} />
								</button>
							</div>
						)}
						<table className={classes["brick-table"]}>
							<thead>
								<tr>
									<th className={classes["label-cell"]}>{t("Colours")}</th>
									<th className={classes["amount-cell"]}>{t("Amount")}</th>
									<th className={classes["amount-cell"]}>{t("Order QTY")}</th>
								</tr>
							</thead>
							<tbody>
								{orderBricks.brickCounts.map((c, i) => (
									<tr
										key={c.brick.rgba}
										className={clsx(
											classes["brick-row"],
											i % 2 === 0
												? classes["brick-row-a"]
												: classes["brick-row-b"],
										)}
									>
										<td className={classes["label-cell"]}>
											<BrickIcon /> {t("Colour")} {c.brick.identifier}
										</td>
										<td className={classes["amount-cell"]}>
											<AmountPill isOdd={i % 2 === 1}>{c.count}</AmountPill>
										</td>
										<td className={classes["amount-cell"]}>
											<AmountPill isOdd={i % 2 === 1}>
												{c.packBricksCount}
											</AmountPill>
										</td>
									</tr>
								))}
							</tbody>
							<tfoot>
								<tr className={classes["total-bricks-row"]}>
									<td className={classes["label-cell"]}>{t("Total bricks")}</td>
									<td className={classes["amount-cell"]}>
										<AmountPill isOdd>{orderBricks.totalAmount}</AmountPill>
									</td>
									<td className={classes["amount-cell"]}>
										<AmountPill isOdd>
											{orderBricks.totalPackBricksAmount}
										</AmountPill>
									</td>
								</tr>
								<tr>
									<td className={classes["label-cell"]}>
										{t("Total packs (of 100)")}
									</td>
									<td className={classes["amount-cell"]} />
									<td className={classes["amount-cell"]}>
										<AmountPill isOdd>
											{orderBricks.totalPacksAmount}
										</AmountPill>
									</td>
								</tr>
							</tfoot>
						</table>
					</div>
					<button
						type="button"
						className={classes["handle"]}
						onClick={() => setOpen((p) => !p)}
					>
						<img {...pick(handleImage, "src", "width", "height")} alt="" />
					</button>
				</div>
			</div>
		</div>
	);
}

export default ColoursCostSummary;
