function ContrastIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
		>
			<path
				d="M12.8399 1.08967C12.3833 0.610247 11.6186 0.610247 11.1621 1.08967C9.58426 2.74682 3.18 9.78516 3.18 14.94C3.18 19.8116 7.12944 23.27 12 23.27C16.8716 23.27 20.82 19.8116 20.82 14.94C20.82 9.78516 14.4168 2.74682 12.8399 1.08967Z"
				fill="#C2F3FF"
			/>
			<path
				d="M20.82 14.94C20.82 9.78518 14.4167 2.74684 12.8398 1.08969C12.621 0.860349 12.318 0.730364 12.001 0.72998V23.27C16.8716 23.27 20.82 19.8116 20.82 14.94Z"
				fill="#66E1FF"
			/>
			<path
				d="M12 23.27C7.12944 23.27 3.18 19.8116 3.18 14.94C3.18 9.78518 9.58426 2.74684 11.1621 1.08969C11.6186 0.610269 12.3833 0.610269 12.8399 1.08969C14.4168 2.74684 20.82 9.78518 20.82 14.94C20.82 19.8116 16.8716 23.27 12 23.27ZM12 23.27L12.001 0.72998M14.941 3.45244V22.8241M17.88 7.38614V21.2424"
				stroke="#3C3C3C"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
}

export default ContrastIcon;
