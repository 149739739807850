import { clsx } from "clsx";
import { useState, useMemo, useEffect, useRef } from "preact/hooks";
import type { JSX, ReactNode } from "preact/compat";
import { mergeRefs } from "react-merge-refs";
import type { GenericSize } from "@brickme/project-core/src/model/geom.ts";
import createUtilityCanvas from "@brickme/project-core/src/render/create-utility-canvas.ts";
import { multiplySize } from "@brickme/project-core/src/model/geom.ts";
import {
	realWorldPictureSize,
	maxImageZoom,
} from "@brickme/project-core/src/model/picture.ts";
import useMeasure from "~/hooks/use-measure.ts";
import useIsMounted from "~/hooks/use-is-mounted.ts";
import {
	useActiveRenderMode,
	useCurrentBrickedBuild,
	usePicture,
	useSourceImageBitmap,
} from "../context.tsx";
import { createRenderer } from "./create-renderer.ts";
import type { RenderRunner, RenderSource } from "./render-runner-type.ts";
import useImageZoomDragging from "./use-image-zoom-dragging.ts";
import useImageZoomPinch from "./use-image-zoom-pinch.ts";
import classes from "./picture-render.module.css";

const minTotalMargin = 20;

function createDrawSize(
	brickedDimensions: GenericSize<number>,
	{ width: parentWidth, height: parentHeight }: GenericSize<number>,
) {
	if (brickedDimensions.width === 0 || brickedDimensions.height === 0) {
		return { width: 0, height: 0 };
	}

	const worldSize = realWorldPictureSize(brickedDimensions);
	const availableWidth = Math.max(0, parentWidth - minTotalMargin);
	const availableHeight = Math.max(0, parentHeight - minTotalMargin);
	const xScaleToContain = availableWidth / worldSize.width.mm;
	const yScaleToContain = availableHeight / worldSize.height.mm;
	const useScaleToContain = Math.min(xScaleToContain, yScaleToContain);
	const containedWidth = Math.floor(useScaleToContain * worldSize.width.mm);
	// Note: Unsure about brickSize here - seems to count frame dimensions
	const brickSize = Math.floor(containedWidth / brickedDimensions.width);

	const canvasWidth = brickSize * brickedDimensions.width;
	const canvasHeight = brickSize * brickedDimensions.height;
	return { width: canvasWidth, height: canvasHeight };
}

const imageZoomStep = 0.1;

type PictureRenderProps = {
	readonly className?: string;
	readonly overlay?: (size: GenericSize<number>) => ReactNode;
};

function PictureRender({ className, overlay }: PictureRenderProps) {
	const bricked = useCurrentBrickedBuild();

	// Overlay
	const [overlayChildren, setOverlayChildren] = useState<ReactNode>();

	// Render worker
	const [renderer, setRenderer] = useState<
		RenderRunner<HTMLCanvasElement> | undefined
	>();
	const isMounted = useIsMounted();
	useEffect(() => {
		(async () => {
			const newRenderer = await createRenderer();
			if (isMounted()) {
				setRenderer(newRenderer);
			}
		})();
	}, []);
	useEffect(() => {
		return () => {
			renderer?.dispose();
		};
	}, [renderer]);

	// Canvas
	const [canvas, setCanvas] = useState<HTMLCanvasElement | null>(null);
	const [setCanvasParentRef, { width: parentWidth, height: parentHeight }] =
		useMeasure<HTMLElement>();
	const brickedImage = bricked?.image;
	useEffect(() => {
		if (!canvas || !renderer) {
			return;
		}

		renderer.setCanvas(canvas);
	}, [canvas, renderer]);
	const sourceImage = useSourceImageBitmap();
	useEffect(() => {
		if (renderer) {
			renderer.setSourceImage(sourceImage, document.createElement("canvas"));
		}
	}, [sourceImage, renderer]);

	// Perform render
	const brickedImageRef = useRef(brickedImage);
	brickedImageRef.current = brickedImage;
	const { picture, patchPicture } = usePicture();
	const pictureRef = useRef(picture);
	pictureRef.current = picture;
	const sourceRenderSource = useMemo(
		(): RenderSource => ({
			type: "source",
			picture,
		}),
		[sourceImage, picture],
	);
	const brickedRenderSource = useMemo(
		(): RenderSource | undefined =>
			brickedImage
				? {
						type: "bricked",
						build: brickedImage,
					}
				: undefined,
		[brickedImage],
	);
	const { activeRenderMode } = useActiveRenderMode();
	const renderSource =
		activeRenderMode === "source" ? sourceRenderSource : brickedRenderSource;
	useEffect(() => {
		if (!renderer || !renderSource) {
			return;
		}

		let buildSize;
		switch (renderSource.type) {
			case "source":
				buildSize = multiplySize(
					renderSource.picture.numberOfBasePlates,
					renderSource.picture.basePlateSize,
				);
				break;
			case "bricked":
				buildSize = renderSource.build;
				break;
		}

		const drawSize = createDrawSize(buildSize, {
			width: parentWidth,
			height: parentHeight,
		});
		const utilityCanvas = createUtilityCanvas(
			{ buildMap: buildSize, drawBounds: drawSize },
			(width, height) => {
				const canvas = document.createElement("canvas");
				canvas.width = width;
				canvas.height = height;
				return canvas;
			},
		);
		renderer.setSource(renderSource, utilityCanvas, drawSize);
		if (overlay) {
			setOverlayChildren(overlay(drawSize));
		} else {
			setOverlayChildren(undefined);
		}
	}, [renderSource, overlay, parentWidth, parentHeight, renderer]);

	const { imageZoomCanvasHandlers, isDragging } = useImageZoomDragging();
	const { isPinching, imageZoomPinchCanvasRef } = useImageZoomPinch();

	// Image zoon with mouse wheel
	const { imageZoom } = picture;
	const onWheel = (e: JSX.TargetedWheelEvent<HTMLCanvasElement>) => {
		if (e.deltaY === 0) {
			return;
		}

		const direction = e.deltaY / Math.abs(e.deltaY);
		patchPicture({
			imageZoom: Math.min(
				maxImageZoom,
				Math.max(1, imageZoom + direction * imageZoomStep),
			),
		});
	};

	return (
		<div
			ref={setCanvasParentRef}
			className={clsx(classes["picture-render"], className)}
		>
			{/* Since we're using offscreen canvas, need to set width height on that */}
			<canvas
				ref={mergeRefs([setCanvas, imageZoomPinchCanvasRef])}
				className={clsx(isDragging && classes["is-dragging"])}
				{...(isPinching ? {} : imageZoomCanvasHandlers)}
				width={0}
				height={0}
				onWheel={onWheel}
				style={{
					touchAction: "none",
					userSelect: "none",
				}}
			/>
			{overlayChildren}
		</div>
	);
}

export default PictureRender;
