import { useTranslator } from "~/i18n/context.tsx";
import Button from "~/components/button.tsx";
import type { Mode } from "../mode.ts";
import AddColourModal from "./add-colour-modal.tsx";
import GetInstructionsModal from "./get-instructions-modal.tsx";
import addColoursButtonImage from "./add-colours-button.png?w=177&h=117&format=webp&as=metadata";
import classes from "./complete-kit-only-menu.module.css";

const addColourModalId = "add-colour-modal";
const getInstructionsModalId = "get-instructions-modal";

type CompleteKitOnlyMenuProps = {
	readonly code: string;
	readonly numberOfKits: number;
	readonly onChangeMode: (mode: Mode) => void;
};

function CompleteKitOnlyMenu({
	code,
	onChangeMode,
	numberOfKits,
}: CompleteKitOnlyMenuProps) {
	const t = useTranslator();
	return (
		<>
			<AddColourModal
				id={addColourModalId}
				onConfirm={() => onChangeMode("colour")}
			/>
			<GetInstructionsModal
				id={getInstructionsModalId}
				numberOfKits={numberOfKits}
				code={code}
			/>
			<div className={classes["bottom-actions"]}>
				<Button
					type="button"
					variant="black-line-blue-text"
					commandfor={getInstructionsModalId}
					command="show-modal"
				>
					{t("Get instructions")}
				</Button>
			</div>
			<button
				type="button"
				commandfor={addColourModalId}
				command="show-modal"
				className={classes["add-colours-button"]}
				aria-label={t("Add colours?")}
			>
				<img
					src={addColoursButtonImage.src}
					width={addColoursButtonImage.width}
					height={addColoursButtonImage.height}
					alt={t("Add colours?")}
				/>
			</button>
		</>
	);
}

export default CompleteKitOnlyMenu;
