function BrickIcon() {
	return (
		<svg
			width="26"
			height="26"
			viewBox="0 0 26 26"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle cx="13" cy="13" r="13" fill="#E83044" />
			<path
				d="M12.4491 14.1944V18.6586L6 15.7257V11.2616L12.4491 14.1944ZM13.0567 14.1985V18.6667L20 15.6568V11.1603L13.0567 14.1985ZM13 11.4034C14.669 11.4034 15.9167 10.7998 15.9167 10.2569V9.15509C15.2928 9.6088 14.2396 9.89641 13 9.89641C11.7604 9.89641 10.7072 9.6088 10.0833 9.15509V10.2569C10.0833 10.7998 11.331 11.4034 13 11.4034ZM16.5243 9.33738V10.2569C16.5243 11.2575 15.0093 12.011 13 12.011C10.9907 12.011 9.47569 11.2575 9.47569 10.2569V9.4184L6.43345 10.7957L12.7569 13.6678L19.5544 10.6944L16.5243 9.33333V9.33738ZM13 7C11.331 7 10.0833 7.60359 10.0833 8.14236C10.0833 8.68113 11.331 9.28877 13 9.28877C14.669 9.28877 15.9167 8.68519 15.9167 8.14236C15.9167 7.59954 14.669 7 13 7Z"
				fill="white"
			/>
		</svg>
	);
}

export default BrickIcon;
