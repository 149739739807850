import { clsx } from "clsx";
import { useRef, useState } from "preact/hooks";
import type { ModalProps } from "~/components/modal.tsx";
import Modal from "~/components/modal.tsx";
import Button from "~/components/button.tsx";
import { useTranslator } from "~/i18n/context.tsx";
import SummaryText from "~/components/summary-text.tsx";
import definition1Image from "./definition-1.png?w=153&h=153&format=webp&as=metadata";
import definition2Image from "./definition-2.png?w=153&h=153&format=webp&as=metadata";
import definition3Image from "./definition-3.png?w=153&h=153&format=webp&as=metadata";
import definition4Image from "./definition-4.png?w=153&h=153&format=webp&as=metadata";
import definition5Image from "./definition-5.png?w=153&h=153&format=webp&as=metadata";
import definition6Image from "./definition-6.png?w=153&h=153&format=webp&as=metadata";
import lighting1Image from "./lighting-1.png?w=153&h=153&format=webp&as=metadata";
import lighting2Image from "./lighting-2.png?w=153&h=153&format=webp&as=metadata";
import lighting3Image from "./lighting-3.png?w=153&h=153&format=webp&as=metadata";
import lighting4Image from "./lighting-4.png?w=153&h=153&format=webp&as=metadata";
import lighting5Image from "./lighting-5.png?w=153&h=153&format=webp&as=metadata";
import lighting6Image from "./lighting-6.png?w=153&h=153&format=webp&as=metadata";
import classes from "./best-results-modal.module.css";
import CheckIcon from "~/features/editor/check-icon.tsx";
import CrossIcon from "~/components/icons/cross.tsx";

type BestResultsModalProps = Pick<ModalProps, "id" | "showModalOnMount">;

function BestResultsModal(props: BestResultsModalProps) {
	const dialogRef = useRef<HTMLDialogElement>(null);
	const t = useTranslator();
	const [step, setStep] = useState<1 | 2>(1);
	const onNext = () => {
		if (step === 1) {
			setStep(2);
			return;
		}
		dialogRef.current?.close();
	};
	return (
		<Modal
			{...props}
			dialogRef={dialogRef}
			size="small"
			bodyClassName={classes["modal-body"]}
		>
			<h2>{t("For best results use these tips")}</h2>
			{step === 1 && (
				<div className={classes["step-content"]}>
					<div className={classes["example-images"]}>
						<ExampleImage
							image1={definition1Image}
							image2={definition2Image}
							isCorrect={false}
						/>
						<ExampleImage
							image1={definition3Image}
							image2={definition4Image}
							isCorrect={false}
						/>
						<ExampleImage
							image1={definition5Image}
							image2={definition6Image}
							isCorrect={true}
						/>
					</div>
					<div className={classes["step-content-text"]}>
						<h3>{t("Definition")}</h3>
						<SummaryText>
							{t(
								"If you upload an image of more than one person, try choosing one where the faces are close together. Zoom in to crop the image and focus on the important part, like a person’s face.",
							)}
						</SummaryText>
					</div>
				</div>
			)}
			{step === 2 && (
				<div className={classes["step-content"]}>
					<div className={classes["example-images"]}>
						<ExampleImage
							image1={lighting1Image}
							image2={lighting2Image}
							isCorrect={false}
						/>
						<ExampleImage
							image1={lighting3Image}
							image2={lighting4Image}
							isCorrect={false}
						/>
						<ExampleImage
							image1={lighting5Image}
							image2={lighting6Image}
							isCorrect={true}
						/>
					</div>
					<div className={classes["step-content-text"]}>
						<h3>{t("Lighting")}</h3>
						<SummaryText>
							{t(
								"Avoid images that are over-exposed, or images with dark shadows. Prefer images with good lighting and sharp, contrasting colours.",
							)}
						</SummaryText>
					</div>
				</div>
			)}
			<div className={classes["actions"]}>
				<div className={classes["progress"]}>
					<div
						className={clsx(
							classes["progress-step"],
							step === 1 && classes["progress-step-active"],
						)}
					/>
					<div
						className={clsx(
							classes["progress-step"],
							step === 2 && classes["progress-step-active"],
						)}
					/>
				</div>
				<Button
					type="button"
					variant="blue"
					onClick={onNext}
					className={classes["next-button"]}
				>
					{t("Next")}
				</Button>
			</div>
		</Modal>
	);
}

type ExampleImageProps = {
	readonly image1: Pick<HTMLImageElement, "src" | "width" | "height">;
	readonly image2: Pick<HTMLImageElement, "src" | "width" | "height">;
	readonly isCorrect: boolean;
};

function ExampleImage({ image1, image2, isCorrect }: ExampleImageProps) {
	return (
		<div className={classes["example-image"]}>
			<img {...image1} alt="Example 1" />
			<img {...image2} alt="Example 2" />
			<div
				className={clsx(
					classes["example-image-status"],
					isCorrect
						? classes["example-image-status-correct"]
						: classes["example-image-status-incorrect"],
				)}
			>
				{isCorrect ? <CheckIcon /> : <CrossIcon size={16} />}
			</div>
		</div>
	);
}

export default BestResultsModal;
