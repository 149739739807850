import type { ReactNode } from "preact/compat";
import BottomSheet from "./bottom-sheet.tsx";
import classes from "./single-button-bottom-sheet.module.css";

type SingleButtonBottomSheetProps = {
	readonly children: ReactNode;
};

function SingleButtonBottomSheet({ children }: SingleButtonBottomSheetProps) {
	return (
		<BottomSheet open>
			<div className={classes["single-button"]}>{children}</div>
		</BottomSheet>
	);
}

export default SingleButtonBottomSheet;
